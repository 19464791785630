import ApplicationView from './common/ApplicationView';
import MenuCameraManager from '/js/components/MenuCameraManager';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';

/**
 * @class MenuSearch
 * @description This class is responsible for the menu search feature.
 */

export default class MenuSearch
{
  constructor(app, menu)
  {
    this.app = app;
    this.menu = menu;
  }

  start()
  {
    this.container = $('.menu__body-search');
    this.input = $('.menu__body-search-input-input');

    this.input.on('keyup', this.on_input_keyup.bind(this));
  }

  on_input_keyup()
  {
    this.search_cameras(this.input.val());
  }

  search_cameras(camera_name)
  {
    this.menu.fill_menu(0, camera_name);
  }
}
