import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import TaglockMarkerManager from '/js/components/TaglockMarkerManager';
import MenuCameraManager from '/js/components/MenuCameraManager';
import CameraTargetUtilities from '../CameraTargetUtilities';
import PlayerSettings from '../PlayerSettings';

import { Time } from 'ohzi-core';

// This handles the websocket messages
export default class TelemetryController
{
  constructor(app, host, type)
  {
    this.host = host;
    this.type = type;
    this.app = app;

    this.webSocket = undefined;
    this.is_connection_alive = false;

    this.t = 0;
  }

  start()
  {
    this.establish_connection();
  }

  update()
  {
    this.t += Time.delta_time;

    if (this.t > 10 &&
       (this.webSocket.readyState === WebSocket.OPEN ||
        this.webSocket.readyState === WebSocket.CLOSED))
    {
      if (this.is_connection_alive)
      {
        this.is_connection_alive = false;
        this.check_connection_health();
      }
      else
      {
        this.restart_connection();
      }

      this.t = 0;
    }
  }

  check_connection_health()
  {
    this.webSocket.send('ping');
  }

  establish_connection(was_restarted)
  {
    console.log('[TelemetryController:establish_connection]');

    this.webSocket = new WebSocket(`${this.host}`);
    this.webSocket.onopen = this.on_open.bind(this);
    this.webSocket.onmessage = this.on_message.bind(this);
    this.webSocket.onclose = this.on_close.bind(this);
  }

  restart_connection()
  {
    console.log('[TelemetryController:restart_connection]');

    this.show_connection_message();

    if (this.webSocket.readyState === WebSocket.OPEN)
    {
      this.webSocket.close();
    }

    this.establish_connection();
  }

  show_connection_message()
  {
    if (this.app.menu_options_view.show_diagnostic_messages)
    {
      let message = `the ${this.host}<br>websocket was lost at<br>${new Date().toUTCString()}.<br><br>Restarting...`;

      this.app.modal_window_view.show(message);
    }
  }

  on_open(event)
  {
    this.is_connection_alive = true;
  }

  on_message(event)
  {
    if (event.data === 'Welcome to our WebSocket Server')
    {
      return;
    }

    if (event.data === 'ping')
    {
      this.is_connection_alive = true;
      return;
    }

    // console.log(`[TelemetryController:on_message]`);
    if (this.app.menu_view.current_tab.name === this.type.name)
    {
      // console.log(`[TelemetryController:on_message] 2`);
      // console.log(event.data);

      let data = JSON.parse(event.data);

      // TODO: This is a temporary solution until we have a method to link webrtc telemetry to database camera entry if names are different.
      // For now we just always write the archive telmetry to the selected camera
      if (PhysicalCameraManager.selected_camera && this.app.menu_view.current_tab.name === 'archive' && this.type.name === 'archive')
      {
        // console.log(`[TelemetryController:on_message] archive setting all messages to slected camera`);
        data.name = PhysicalCameraManager.selected_camera.name;
      }

      let camera = PhysicalCameraManager.get_by_name(data.name);

      // Rate limit the update of non-selected cameras to 1hz (1000ms)
      let time = new Date().getTime();
      if (camera && data.type !== 'target')
      {
        if (camera !== PhysicalCameraManager.selected_camera)
        {
          if (time < (camera.last_telemetry_update + 1000))
          {
            return;
          }
        }
        camera.last_telemetry_update = time;
      }

      PhysicalCameraManager.update_camera(data.name, data);
      MenuCameraManager.camera_updated(data.name);

      if (camera && camera.fly_on_valid_telemetry && camera === PhysicalCameraManager.selected_camera &&
        data.name === PhysicalCameraManager.selected_camera.name)
      {
        // fix doesn't exist in Axis camera telemetry stream
        // if (CameraTargetUtilities.is_valid_non_zero_position(data.cam_lon, data.cam_lat, data.cam_alt) && data.fix > 0)
        if (CameraTargetUtilities.is_valid_non_zero_position(parseFloat(data.cam_lon), parseFloat(data.cam_lat), parseFloat(data.cam_alt)))
        {
          camera.fly_on_valid_telemetry = false;
          camera.show_map_marker(false);
          camera.fly_to_camera_marker();
        }
      }

      switch (data.type)
      {
      case 'full':
        // We don't do anything
        break;
      case 'camera_target':
        if (camera)
        {
          if (PlayerSettings.player_tag_lock)
          {
            CameraTargetUtilities.compute_and_set_camera_image_and_icon(camera);
          }
        }
        break;
      case 'camera':
        if (camera)
        {
          if (PlayerSettings.player_tag_lock)
          {
            // console.log(`[TelemetryController:on_message] type camera and setting target`);
            CameraTargetUtilities.compute_and_set_camera_image_and_icon(camera);
          }
        }
        break;
      case 'target':
        PlayerSettings.add_option_to_target_select(data.name);
        TaglockMarkerManager.add_option_to_taglock_select(data.name, data);
        TaglockMarkerManager.update_marker(data.name, data);
        if (PlayerSettings.player_tag_lock)
        {
          this.__update_camera_from_target(data);
        }
        break;
      }
    }
  }

  on_close(ev)
  {
    this.is_connection_alive = false;
  }

  __update_camera_from_target(data)
  {
    if (PlayerSettings.selected_target === data.name)
    {
      if (PhysicalCameraManager.selected_camera)
      {
        data.name = PhysicalCameraManager.selected_camera.name;
        data.tgt_name = PlayerSettings.selected_target;

        PhysicalCameraManager.update_camera(data.name, data);
        CameraTargetUtilities.compute_and_set_camera_image_and_icon(PhysicalCameraManager.selected_camera);
      }
      if (PhysicalCameraManager.tandem_mode)
      {
        data.name = PhysicalCameraManager.selected_camera_tandem.name;

        PhysicalCameraManager.update_camera(data.name, data);
        CameraTargetUtilities.compute_and_set_camera_image_and_icon(PhysicalCameraManager.selected_camera_tandem);
      }
    }
  }

  __show_error_messages(response)
  {
    let selected_camera = PhysicalCameraManager.selected_camera;
    let gps_enabled = selected_camera ? selected_camera.gps_enabled : undefined;

    if (gps_enabled && gps_enabled.toLowerCase() === 'yes')
    {
      let error_message = undefined;

      // if (response.responseJSON && response.responseJSON.result === 'no_telemetry_available')
      // {
      //   error_message = response.responseJSON.description;
      // }
      // else if (response.statusText === 'parsererror')
      // {
      //   // error_message = response.responseText;
      // }

      console.log(response);
      if (error_message)
      {
        this.app.modal_window_view.show(error_message);
      }
    }
  }
}
