import ApplicationView from './common/ApplicationView';
import RecordingManager from '/js/components/RecordingManager';
import TimeUtilities from '../TimeUtilities';
import RecordingsListRowManager from '../RecordingsListRowManager';

// This handles the calendar component and defines
// fragments for recordings of a day
/**
 * @class CalendarView
 * @description - Represent the calender view when clicking on Recordings or Archive Camera.
 * @function get_recordings_from_camera() - Fetches all the recording from a camera when a user selects a camera on the Recording tab.
 */
export default class CalendarView extends ApplicationView
{
  constructor(app)
  {
    super('calendar', $('.calendar'));
    this.app = app;

    this.month_mames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    this.__set_current_date();

    this.recording_manager = new RecordingManager();
    this.recordings_from_camera = [];
  }

  start()
  {
    this.days = $('.calendar__days-day');
    this.title = $('.calendar__title');

    this.__set_title(this.current_month_name, this.current_year);
    this.__set_days(this.current_month, this.current_year);
  }

  // This function is called by the UI (see calendar.pug) when the user clicks on the back button.
  prev_month()
  {
    let next_month = this.current_month === 0 ? 11 : this.current_month - 1;
    let next_year = next_month === 11 ? this.current_year - 1 : this.current_year;

    this.__set_current_date(next_month, next_year);
    this.__set_title(this.current_month_name, this.current_year);
    this.__set_days(this.current_month, this.current_year);
    this.__set_active_days();
  }

  // This function is called by the UI (see calendar.pug) when the user clicks on the next button.
  next_month()
  {
    let prev_month = this.current_month === 11 ? 0 : this.current_month + 1;
    let prev_year = prev_month === 0 ? this.current_year + 1 : this.current_year;

    this.__set_current_date(prev_month, prev_year);
    this.__set_title(this.current_month_name, this.current_year);
    this.__set_days(this.current_month, this.current_year);
    this.__set_active_days();
  }

  // This function is called by the UI (see calendar.pug) when the user clicks on a day.
  // This function creates as fragments as recordings has the selected day and then
  // it plays the first fragment of its day.
  select_day(day)
  {
    if ($(day).hasClass('active'))
    {
      this.days.removeClass('selected');
      $(day).addClass('selected');

      let day_number = $(day).data('day_number');

      this.current_day = day_number;

      this.app.player_view.playback_bar.clean_fragments();

      this.recording_manager.clear();
      RecordingsListRowManager.clear();

      console.dir({ recordings: this.recordings_from_camera });

      // this.recordings_from_camera was populated before by MenuView(by calling get_recordings_from_camera or get_recordings_from_server)
      for (let i = 0; i < this.recordings_from_camera.length; i++)
      {
        let recording_id = this.recordings_from_camera[i]['@attributes'].recordingid;
        let start_date   = this.recordings_from_camera[i]['@attributes'].starttimelocal;
        let end_date     = this.recordings_from_camera[i]['@attributes'].stoptimelocal;
        let file_name    = this.recordings_from_camera[i]['@attributes'].filename;
        let encodedurl    = this.recordings_from_camera[i]['@attributes'].encodedurl;
        let downloadurl    = this.recordings_from_camera[i]['@attributes'].downloadurl;

        // we add on recording_manager all retrieved recordings.
        this.recording_manager.add_recording_date_range(recording_id, start_date, end_date, file_name, encodedurl, downloadurl);
      }

      let current_date = new Date(this.current_year, this.current_month, day_number);
      let recordings = this.recording_manager.get_recordings_on_date(current_date); // retrieve the recordings from the recording_manager that are the same with the selected date

      for (let i = 0; i < recordings.length; i++)
      {
        let recording = recordings[i];
        // console.log(`[CalendarView:select_day] Add row`);
        // console.dir(recording);

        let day_start_time_ms       = recording.get_local_start_date_in_ms();
        let day_end_time_ms         = recording.get_local_start_date_in_ms() + (recording.duration_in_seconds_from_start * 1000);

        let new_fragment = this.app.player_view.playback_bar.add_fragment(
          recording,
          day_start_time_ms,
          day_end_time_ms
        );

        RecordingsListRowManager.add_row(new_fragment, day_start_time_ms, day_end_time_ms);
      }

      if (this.app.menu_view.current_tab.name == 'archive')
      {
        // Set the player view into archive mode
        this.app.player_view.set_archive_mode();
      }
      else
      {
        this.app.player_view.playback_bar.add_telemetry_fragments(current_date);
        // Set the player view into recording mode
        this.app.player_view.set_recordings_mode();
      }
      // Set the recordings_list view visible this method is a member of ArchiveMode class or any other mode that we have selected.
      this.app.menu_view.current_tab.on_day_selected(this.app);
    }
  }

  // Called by MenuView when a camera is selected on recordings tab
  get_recordings_from_camera(camera)
  {
    // console.log(`[CalendarView:get_recordings_from_camera]`);
    camera.get_recordings_from_camera(this.set_recordings_from_camera, this, 'recordings');
  }

  // Called by MenuView when a camara is selected on archive tab  (This called by ArchiveMode class)

  /**
   * @function get_recordings_from_server()
   * @description Retirieve recordings from the server.This function is called when on_camera_selected called, on ArchiveMode or any other menu mode of the application.
   * @param {Physical} camera
   *
   */
  get_recordings_from_server(camera)
  {
    // console.log(`[CalendarView:get_recordings_from_server]`);
    camera.get_recordings_from_camera(this.set_recordings_from_camera, this, 'exportedrecordings');
  }

  // Called when a collection of recordings is obtained from a physical camera
  set_recordings_from_camera(recordings_from_camera, camera)
  {
    // console.log(`[CalendarView:set_recordings_from_camera]`);
    // console.log(camera)
    // console.dir(recordings_from_camera);

    this.recordings_from_camera = recordings_from_camera;

    this.__set_active_days();
  }
  /*

  moment("12-25-1995", ["MM-DD-YYYY", "YYYY-MM-DD"]);
  */

  show()
  {
    super.show();
    this.__set_days(this.current_month, this.current_year);
  }

  hide()
  {
    super.hide();
    this.t = 0.5;
  }

  // Enable in the UI (put in blue) days with recordings
  __set_active_days()
  {
    // console.log(`[CalendarView:__set_active_days]`);

    this.days.removeClass('active');
    this.days.removeClass('selected');

    this.recordings_from_camera = this.recordings_from_camera.filter(Boolean);
    // console.dir(this.recordings_from_camera);

    for (let i = 0; i < this.recordings_from_camera.length; i++)
    {
      let start_date = this.recordings_from_camera[i]['@attributes'].starttimelocal;
      let end_date = this.recordings_from_camera[i]['@attributes'].stoptimelocal;

      let days = TimeUtilities.get_days_between(start_date, end_date);
      // console.log(`Days betweeen: ${days}`);
      // console.dir(days);

      for (let j = 0; j < days.length; j++)
      {
        if (this.__id_recording_in_month_moment(days[j].start))
        {
          let day_number = days[j].start.date();
          let day_with_recording_el = $(this.days[day_number + this.current_day_of_week - 1]);
          // console.log("Day number: " + day_number, "Month offset: " + this.current_day_of_week, day_with_recording_el)

          day_with_recording_el.addClass('active');
          day_with_recording_el.data('day_number', day_number);
        }
      }
    }
  }

  __get_offset_time(start_time, end_time)
  {
    return THREE.Math.clamp(Math.floor(end_time - start_time), 0, 86400000); // - star_time ?
  }

  __is_recording_in_month(date)
  {
    return date.getMonth() === this.current_month &&
           date.getFullYear() === this.current_year;
  }

  __id_recording_in_month_moment(date)
  {
    return date.month() === this.current_month && date.year() === this.current_year;
  }

  __first_weekday_of(month, year)
  {
    return new Date(year, month).getDay();
  }

  __days_in_month(month, year)
  {
    return new Date(year, month + 1, 0).getDate();
  }

  __set_title(month, year)
  {
    this.title.text(`${month} ${year}`);
  }

  __set_current_date(month, year)
  {
    this.date = month !== undefined && year !== undefined ? new Date(year, month) : new Date();
    this.current_year = this.date.getFullYear();
    this.current_month = this.date.getMonth();
    this.current_month_name = this.month_mames[this.date.getMonth()];
    this.current_day = this.date.getDate();
    this.current_day_of_week = this.__first_weekday_of(this.current_month, this.current_year);
  }

  // Fill the calendar with days of a specific month and year
  __set_days(month, year)
  {
    let day = 1;

    for (let i = 0; i < 41; i++)
    {
      this.days[i].innerText = '';
      $(this.days[i]).removeClass('disabled');
      $(this.days[i]).removeClass('selected');
      $(this.days[i]).removeClass('active');

      if (i >= this.__first_weekday_of(month, year))
      {
        if (day <= this.__days_in_month(month, year))
        {
          this.days[i].innerText = day;
        }
        else
        {
          $(this.days[i]).addClass('disabled');
        }

        day++;
      }
      else
      {
        $(this.days[i]).addClass('disabled');
      }
    }
  }
}
