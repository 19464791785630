import { Configuration } from 'ohzi-core';
import { MathUtilities } from 'ohzi-core';
import { Input } from 'ohzi-core';
import { Screen } from 'ohzi-core';

import ApplicationView from './common/ApplicationView';
import PhysicalCameraManager from '../PhysicalCameraManager';
import CameraTargetUtilities from '../CameraTargetUtilities';
import CesiumHandler from '../CesiumHandler';
import PlayerSettings from '../PlayerSettings';
import TaglockMarkerManager from '/js/components/TaglockMarkerManager';

// This class is responsible of the player container
// Here's the functionality to move and resize the player

/**
 * @class PlayerContainer
 * @description - Responsible for the functionallity of the playerview.
 * @function __check_for_double_click() - When a user double clicks the player view the marker on the map appears and the video screen show at that point.
 */
export default class PlayerContainer extends ApplicationView
{
  constructor(app)
  {
    super('player-container', $('.player-container'));

    this.app = app;
    this.loading = undefined;

    this.current_pos_index = 1;
    this.is_maximized = false;
    this.current_size = 100;
    this.is_showing = false;
  }

  // Called by MainApplication
  start()
  {
    super.start();

    this.loading                    = $('.player-container__loading');
    this.maximize_button            = $('.player__maximize-button');
    this.minimize_button            = $('.player__minimize-button');
    this.move_button                = $('.player__button-move');

    this.zoom_in_button             = $('.player__zoom-in-button');
    this.zoom_out_button            = $('.player__zoom-out-button');

    this.__recalculate_positions();
    this.move(this.positions['top-right']);

    if (!Configuration.is_mobile)
    {
      this.container.addClass('player-container--desktop-horizontal');
    }

    this.__set_loading_video_url();

    // TODO: Get this instance from PlayerView
    this.player_video_element = document.querySelector('.player__video');
    this.player_video_element.addEventListener('canplaythrough', this.on_video_play.bind(this));
  }

  // Called by MenuView when we are on live state
  // Called by CalendarView when we are on recordings state
  show(transition_delay)
  {
    if (!this.is_showing)
    {
      super.show(transition_delay);

      this.current_size = 50;

      // Recalculate sizes of player and cesium only on mobile
      this.__set_size(window.innerWidth, window.innerHeight);
      this.__set_cesium_size(window.innerWidth, window.innerHeight);

      this.app.on_resize();

      this.is_showing = true;
    }
  }

  // Called by MenuView when the state is changed
  hide(transition_delay)
  {
    if (this.is_showing)
    {
      super.hide(transition_delay);

      this.current_size = 100;

      this.__set_cesium_size(window.innerWidth, window.innerHeight);

      this.app.on_resize();

      this.app.menu_view.current_tab.on_player_hide(this);

      this.is_showing = false;
    }
  }

  update()
  {
    super.update();
    this.__check_for_double_click();
  }

  on_video_play()
  {
    let video_width = $(this.player_video_element).width();
    let video_height = $(this.player_video_element).height();
    // console.log('[PlayerContainer:on_video_play]', video_width, video_height);

    if (PhysicalCameraManager.selected_camera)
    {
      PhysicalCameraManager.selected_camera.map_marker.set_video_resolution(video_height, video_width);
      // PhysicalCameraManager.selected_camera.map_marker.set_image_url(this.player_video_element);
    }

    this.app.camera_controller.update_camera_distance();

    if (!Configuration.is_mobile)
    {
      if (video_height > video_width)
      {
        this.container.removeClass('player-container--desktop-horizontal');
        this.container.addClass('player-container--desktop-vertical');
      }
      else
      {
        this.container.removeClass('player-container--desktop-vertical');
        this.container.addClass('player-container--desktop-horizontal');
      }

      this.on_resize();
    }
  }

  __check_for_double_click()
  {
    if (!PhysicalCameraManager.selected_camera)
    {
      return;
    }

    if (Input.double_click)
    {
      if (Input.mouse_is_within_bounds())
      {
        if (this.app.camera_calibration_view.camera_calibration_pan_active)
        {
          this.app.camera_calibration_view.set_camera_pan(this.__get_pan_from_mouse_position());
        }
        else
        {
          if (PhysicalCameraManager.selected_camera.lens_type === 'fisheye')
          {
            // Only perform reverse tracking calc if using fisheye camera
            this.__compute_and_set_pan_and_tilt_from_mouse_position();

            CameraTargetUtilities.compute_and_set_map_marker_target_position(PhysicalCameraManager.selected_camera);
            this.minimize();

            if (!PhysicalCameraManager.tandem_mode)
            {
              PlayerSettings.toggle_map_lock(true);
              PhysicalCameraManager.selected_camera.fly_to_target_marker();
            }
            else
            {
              // Update tandem camera with target calculated from this camera
              PhysicalCameraManager.selected_camera_tandem.tgt_lon = parseFloat(PhysicalCameraManager.selected_camera.tgt_lon.toFixed(7));
              PhysicalCameraManager.selected_camera_tandem.tgt_lat = parseFloat(PhysicalCameraManager.selected_camera.tgt_lat.toFixed(7));
              PhysicalCameraManager.selected_camera_tandem.tgt_alt = parseFloat(PhysicalCameraManager.selected_camera.tgt_alt.toFixed(7));

              // Set tgt name undefined to hide it on cesium label
              PhysicalCameraManager.selected_camera_tandem.tgt_name = undefined;

              CameraTargetUtilities.compute_and_set_camera_image_and_icon(PhysicalCameraManager.selected_camera_tandem);
            }
          }
          else
          {
            // When using flat camera change the target
            this.__compute_and_set_frame_pixels_from_mouse_position();
          }
        }
      }
    }
  }

  __get_pan_from_mouse_position()
  {
    let canvas_rect = Input.canvas.getBoundingClientRect();
    let NDC = new THREE.Vector2(
      (Input.mouse_pos.x - canvas_rect.left) / Screen.width,
      (Input.mouse_pos.y - canvas_rect.top) / Screen.height
    );
    NDC.x = NDC.x * 2.0 - 1.0;
    NDC.y = (1 - NDC.y) * 2.0 - 1.0;

    let ws_mouse_pos = new THREE.Vector3(NDC.x, NDC.y, -1).unproject(PhysicalCameraManager.camera_controller.camera);
    let dir = ws_mouse_pos.sub(PhysicalCameraManager.camera_controller.camera.position).normalize();

    // make orientation clockwise
    let marker_orientation = (180 + this.get_vector_orientation(dir)) % 360;

    return marker_orientation;
  }

  __compute_and_set_pan_and_tilt_from_mouse_position()
  {
    let canvas_rect = Input.canvas.getBoundingClientRect();
    let NDC = new THREE.Vector2(
      (Input.mouse_pos.x - canvas_rect.left) / Screen.width,
      (Input.mouse_pos.y - canvas_rect.top) / Screen.height
    );
    NDC.x = NDC.x * 2.0 - 1.0;
    NDC.y = (1 - NDC.y) * 2.0 - 1.0;

    let ws_mouse_pos = new THREE.Vector3(NDC.x, NDC.y, -1).unproject(PhysicalCameraManager.camera_controller.camera);
    let dir = ws_mouse_pos.sub(PhysicalCameraManager.camera_controller.camera.position).normalize();

    // make orientation clockwise
    let marker_orientation = (180 + this.get_vector_orientation(dir)) % 360;

    let corrected_dir = dir.clone().applyAxisAngle(new THREE.Vector3(0, 1, 0), -Math.PI / 2);
    let xz_dir = new THREE.Vector2(corrected_dir.x, corrected_dir.z).normalize();
    let orientation_angle = Math.atan2(xz_dir.y, xz_dir.x);

    let forward_dir = dir.clone().applyAxisAngle(new THREE.Vector3(0, 1, 0), orientation_angle);

    let marker_tilt = Math.sign(forward_dir.y) * MathUtilities.linear_map(Math.asin(forward_dir.z), 0, -Math.PI, 90, -90);

    PhysicalCameraManager.selected_camera.icon_pan    = parseFloat(marker_orientation.toFixed(2));
    PhysicalCameraManager.selected_camera.icon_tilt   = parseFloat(marker_tilt.toFixed(2));
    PhysicalCameraManager.selected_camera.image_pan   = parseFloat(marker_orientation.toFixed(2));
    PhysicalCameraManager.selected_camera.image_tilt  = parseFloat(marker_tilt.toFixed(2));
  }

  __compute_and_set_frame_pixels_from_mouse_position()
  {
    let canvas_rect = Input.canvas.getBoundingClientRect();
    let NDC = new THREE.Vector2(
      (Input.mouse_pos.x - canvas_rect.left) / Screen.width,
      (Input.mouse_pos.y - canvas_rect.top) / Screen.height
    );
    let pixel_x = Input.mouse_pos.x - canvas_rect.left;
    let pixel_y = Input.mouse_pos.y - canvas_rect.top;

    // NDC.x = NDC.x * 2.0 - 1.0;
    // NDC.y = (1 - NDC.y) * 2.0 - 1.0;

    PhysicalCameraManager.camera_controller.current_mode.set_rotation_center(NDC.x, NDC.y);
  }

  get_vector_orientation(vec3)
  {
    let rotation_fix = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), THREE.Math.degToRad(-90));
    vec3.applyQuaternion(rotation_fix);

    let new_right = new THREE.Vector2(vec3.x, vec3.z).normalize();
    let x = Math.atan2(new_right.y, new_right.x);
    return ((x > 0 ? x : (2 * Math.PI + x)) * 360 / (2 * Math.PI));
  }

  // This function is called by the UI (see player.pug)
  // when the user clicks on the cross button.
  close()
  {
    // If the user clicks on the cross icon and the player was maximized
    // it is minimized, instead of closed
    if (this.is_maximized)
    {
      this.minimize();
    }
    else
    {
      if (Configuration.is_ios)
      {
        // this.app.player_view.html_video.pause();
        // this.app.player_view.pause();
      }

      this.app.player_view.html_video.stop();
      PhysicalCameraManager.selected_camera.stop();
      TaglockMarkerManager.show_select_taglock();
      this.hide();
      PhysicalCameraManager.unset_active_camera();

    }
  }

  // This function is called by the UI (see player.pug)
  // when the user clicks on the minimize button.
  minimize()
  {
    this.maximize_button.removeClass('hidden');
    this.minimize_button.addClass('hidden');
    this.move_button.removeClass('hidden');

    this.__exit_fullscreen(this.containerEl);
  }

  // This function is called by the UI (see player.pug)
  // when the user clicks on the maximize button.
  maximize()
  {
    this.app.menu_view.close();

    this.__enter_fullscreen(this.containerEl);
    this.maximize_button.addClass('hidden');
    this.minimize_button.removeClass('hidden');

    this.move_button.addClass('hidden');
  }

  // This function is called by the UI (see player.pug)
  // when the user clicks on the move button.
  move(pos)
  {
    this.__recalculate_positions();
    this.positions_keys = Object.keys(this.positions);

    if (!pos)
    {
      this.current_pos_index++;
      this.current_pos_index = this.current_pos_index % this.positions_keys.length;
    }

    let next_pos = pos || this.__current_position();
    this.containerEl.style.transform = `translate3d(${next_pos.x}px, ${next_pos.y}px, 0)`;
  }

  show_loading()
  {
    this.loading.removeClass('hidden');
  }

  hide_loading()
  {
    this.loading.addClass('hidden');
  }

  is_loading()
  {
    return this.loading.hasClass('hidden') === false;
  }

  // Called by MainApplication when the browser window is resized
  on_resize()
  {
    if (this.initialized)
    {
      if (!Configuration.is_mobile)
      {
        this.__recalculate_positions();
        this.positions_keys = Object.keys(this.positions);
        this.move(this.__current_position());

        this.app.camera_controller.update_camera_distance();
      }
    }
  }

  // Called by MainApplication when the device orientation is changed
  on_orientation_change()
  {
    this.app.on_resize();

    this.__set_size(window.innerHeight, window.innerWidth);
    this.__set_cesium_size(window.innerHeight, window.innerWidth);
  }

  show_zoom_controls()
  {
    this.zoom_in_button.removeClass('hidden');
    this.zoom_out_button.removeClass('hidden');
  }

  hide_zoom_controls()
  {
    this.zoom_in_button.addClass('hidden');
    this.zoom_out_button.addClass('hidden');
  }

  // Put the player in fullscreen mode
  __enter_fullscreen()
  {
    this.last_width = this.container.width();
    this.last_height = this.container.height();
    this.container.width('100%');
    this.container.height('100%');
    this.current_size = 100;

    if (!Configuration.is_mobile)
    {
      this.__recalculate_positions();
      this.move(this.positions['top-left']);
    }

    this.is_maximized = true;
    this.app.on_resize();
  }

  // Restore the player to normal size
  __exit_fullscreen()
  {
    this.current_size = 50;

    if (Configuration.is_mobile)
    {
      this.container.css('width', this.last_width);
      this.container.css('height', this.last_height);
    }
    else
    {
      this.container.css('width', '');
      this.container.css('height', '');
      this.__recalculate_positions();
      this.move(this.__current_position());
    }

    this.is_maximized = false;
    this.app.on_resize();
  }

  __current_position()
  {
    return this.positions[this.positions_keys[this.current_pos_index]];
  }

  __recalculate_positions()
  {
    this.positions = {
      'top-left': { x: this.app.menu_view.is_opened ? this.app.menu_view.width() : 0, y: 0 },
      'top-right': { x: window.innerWidth - this.width(), y: 0 },
      'bottom-right': { x: window.innerWidth - this.width(), y: window.innerHeight - this.height() },
      'bottom-left': { x: this.app.menu_view.is_opened ? this.app.menu_view.width() : 0, y: window.innerHeight - this.height() }
    };
  }

  __set_size(width, height)
  {
    if (Configuration.is_mobile)
    {
      if (height > width)
      {
        this.container.width(`${THREE.Math.clamp(this.current_size * 2, 50, 100)}%`);
        this.container.height(`${this.current_size}%`);
      }
      else
      {
        this.container.width(`${this.current_size}%`);
        this.container.height(`${THREE.Math.clamp(this.current_size * 2, 50, 100)}%`);
      }
    }
  }

  __set_cesium_size(width, height)
  {
    if (Configuration.is_mobile)
    {
      if (height > width)
      {
        $('.cesium-container').width(`${THREE.Math.clamp(this.current_size * 2, 50, 100)}%`);
        $('.cesium-container').height(`${this.current_size}%`);
      }
      else
      {
        $('.cesium-container').width(`${this.current_size}%`);
        $('.cesium-container').height(`${THREE.Math.clamp(this.current_size * 2, 50, 100)}%`);
      }
    }
  }

  __set_loading_video_url()
  {
    let video = this.loading.find('video')[0];

    let source_webm = document.createElement('source');
    source_webm.src = 'videos/loading_camera.webm';
    video.appendChild(source_webm);

    let source_mp4 = document.createElement('source');
    source_mp4.src = 'videos/loading_camera.mp4';
    video.appendChild(source_mp4);
  }
}
