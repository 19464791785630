
import ApplicationView from './common/ApplicationView';
import { ResourceContainer } from 'ohzi-core';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import RoleManager from '/js/components/RoleManager';

// This class is responsible of the player extract recording feature
export default class PlayerExtractRecordingView extends ApplicationView
{
  constructor(player_view, calendar_view)
  {
    super('extract-recording', $('.extract-recording'));

    this.calendar_view = calendar_view;
    this.player_view = player_view;

    this.is_showing = false;

    this.open_button_hidden = false;
  }

  // Called by MainApplication
  start()
  {
    this.open_button = $('.player__button-extract');
    this.input_from = $('.extract-recording__time-input--from');
    this.input_to = $('.extract-recording__time-input--to');
    this.extract_button = $('.extract-recording__button');
    this.extracting_container = $('.extract-recording__container');
    this.extracting_message = $('.extract-recording-message');

    this.input_from.on('input propertychange', this.__on_input_change.bind(this));
    this.input_to.on('input propertychange', this.__on_input_change.bind(this));

    super.start();
  }

  show()
  {
    this.container.removeClass('hidden');
    this.extracting_container.removeClass('hidden');
    this.extracting_message.addClass('hidden');
    this.open_button.addClass('active');
    this.is_showing = true;
  }

  hide()
  {
    this.container.addClass('hidden');
    this.open_button.removeClass('active');
    this.is_showing = false;
  }

  // show(transition_delay)
  // {
  //   if (!this.is_showing)
  //   {
  //     super.show(transition_delay);

  //     this.is_showing = true;
  //   }
  // }

  // hide(transition_delay)
  // {
  //   if (this.is_showing)
  //   {
  //     super.hide(transition_delay);

  //     this.is_showing = false;
  //   }
  // }

  toggle_view()
  {
    // this.is_showing = !this.is_showing;

    if (!this.is_showing)
    {
      this.show();
    }
    else
    {
      this.hide();
    }
  }

  extract()
  {
    console.log(this.input_from.val());
    console.log(this.input_to.val());

    let start_time = this.input_from.val();
    let stop_time = this.input_to.val();

    if (start_time && stop_time)
    {
      let selected_camera = PhysicalCameraManager.selected_camera;

      //let ip = selected_camera.recordings_ip.split(':')[0];
      //let port = selected_camera.recordings_ip.split(':')[1] || 80;
      //let username = selected_camera.username;
      //let password = selected_camera.password;
      let camera_id = selected_camera._id;

      let start_time_ms = this.__get_input_time_in_ms(start_time);
      let stop_time_ms = this.__get_input_time_in_ms(stop_time);

      let ms = start_time_ms + (stop_time_ms - start_time_ms) / 2;

      let fragment = this.player_view.playback_bar.get_fragment_contained_at_ms(ms);
      console.log(`[PlayerExtractRecordingView:extract]`);
      console.dir(this.player_view);
      console.log(ms);

      if (fragment)
      {
        let recording_id = fragment.id;
        let timezone = fragment.timezone;

        let formatted_start_time = this.__get_fomatted_time(start_time, timezone);
        let formatted_stop_time = this.__get_fomatted_time(stop_time, timezone);

        let data = {
          recording_id: recording_id,
          start_time: formatted_start_time,
          stop_time: formatted_stop_time,
          camera_id: selected_camera._id
          // file_name: file_name
        };
        // DG - remove
        let datan = {
          recording_id: recording_id,
          start_time: formatted_start_time,
          stop_time: formatted_stop_time,
          camera_id: camera_id
        };

        let app_config = ResourceContainer.get_resource('config');

        $.ajax({
          type: 'POST',
          url: `${app_config.recordings_api_host}/exportrecording`,
          headers: RoleManager.get_auth_header(),
          timeout: 60000,
          dataType: 'json',
          data: JSON.stringify(data),
          complete: this.__extract_response.bind(this)
        });

        this.player_view.app.recording_telemetry_controller.extract(datan);

        this.__lock_button();
      }
      else
      {
        console.log('fragment not found');
      }
    }
  }

  show_open_button()
  {
    if (!this.open_button_hidden)
    {
      this.open_button.removeClass('hidden');
    }
  }

  hide_open_button(persisted)
  {
    if (persisted)
    {
      this.open_button_hidden = true;
    }

    this.open_button.addClass('hidden');
  }

  // Gets called when playing archive video
  start_streaming(on_video_ready, play_offset, player_video, encodedurl)
  {
    console.log(`[PlayerExtractRecordingView:start_streaming] play_offset: ${play_offset}`);
    console.dir(on_video_ready);
    console.dir(play_offset);
    console.dir(player_video);
    console.dir(encodedurl);

    player_video.container.src = `${encodedurl}#t=${play_offset}`;

    player_video.container.oncanplaythrough = on_video_ready;
    player_video.load();
  }

  __extract_response(response)
  {
    console.log(response.status);
    this.__unlock_button();

    if (response.status !== 0)
    {
      this.extracting_container.addClass('hidden');
      this.extracting_message.removeClass('hidden');
    }
    else
    {
      console.error('Something went wrong', response);
      // this.__change_extract_button_state('Failed', false);
    }
  }

  __get_input_time_in_ms(time)
  {
    time = time.split(':');
    let hours = time[0];
    let minutes = time[1];
    let seconds = time[2];

    let hours_ms = hours * 60 * 60 * 1000;
    let minutes_ms = minutes * 60 * 1000;
    let seconds_ms = seconds * 1000;

    console.log(`[__get_input_time_in_ms] time: ${time}, hours: ${hours}, minutes: ${minutes}, seconds: ${seconds}`);

    return hours_ms + minutes_ms + seconds_ms;
  }

  __get_fomatted_time(time, timezone)
  {
    time = time.split(':');
    // let hours = time[0];
    // let minutes = time[1];
    // let seconds = time[2];
    let hours = ('0' + time[0]).slice(-2);
    let minutes = ('0' + time[1]).slice(-2);
    let seconds = ('0' + time[2]).slice(-2);

    let year = this.calendar_view.current_year;
    let month = ('0' + (this.calendar_view.current_month + 1)).slice(-2);
    let day = ('0' + this.calendar_view.current_day).slice(-2);

    let formatted_time = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezone}`;
    console.log('Formatted time', formatted_time);

    return formatted_time;
  }

  __on_input_change(event)
  {
    const target = event.target;
    const input = event.target.value.replace(/\D/g, '').substring(0, 6); // First ten digits of input only
    let hours = input.substring(0, 2);
    let minutes = input.substring(2, 4);
    let seconds = input.substring(4, 6);

    if (Number(hours) > 23)
    {
      hours = 23;
    }

    if (Number(minutes) > 59)
    {
      minutes = 59;
    }

    if (Number(seconds) > 59)
    {
      seconds = 59;
    }

    target.value = target.value.replace(/[^0-9.]/g, '');

    if (input.length > 4)
    {
      target.value = `${hours}:${minutes}:${seconds}`;
    }
    else if (input.length > 2)
    {
      target.value = `${hours}:${minutes}`;
    }
    else if (input.length > 0)
    {
      target.value = `${hours}`;
    }
  }

  __lock_button()
  {
    this.extract_button.addClass('disabled');
    this.extract_button.text('processing');
  }

  __unlock_button()
  {
    this.extract_button.removeClass('disabled');
    this.extract_button.text('extract');
  }
}
