import { ResourceContainer } from 'ohzi-core';
import PhysicalCameraManager from '../PhysicalCameraManager';
import RecordingTelemetryManager from '../RecordingTelemetryManager';
import RoleManager from '../RoleManager';
import FragmentManager from './FragmentManager';

export default class TelemetryFragmentManager extends FragmentManager
{
  constructor(html_bar_container, html_fragment_container)
  {
    super(html_bar_container, html_fragment_container);

    this.recording_telemetry_manager = new RecordingTelemetryManager();

    this.current_date = undefined;
  }

  add_fragments(current_date)
  {
    this.current_date = current_date;

    let selected_camera = PhysicalCameraManager.selected_camera;
    let gps_enabled = selected_camera ? selected_camera.gps_enabled : undefined;

    if (gps_enabled && gps_enabled.toLowerCase() === 'yes')
    {
      this.get_telemetry_list();
    }
  }

  get_telemetry_list()
  {
    let app_config = ResourceContainer.get_resource('config');

    let data = {
      camera_id: PhysicalCameraManager.selected_camera._id
    };

    $.ajax({
      type: 'POST',
      url: `${app_config.recordings_api_host}/telemetry/external/list`,
      headers: RoleManager.get_auth_header(),
      timeout: 2000,
      dataType: 'json',
      data: JSON.stringify(data),
      complete: this.__external_list_response.bind(this)
    });

    // Testing
    // let temporary_response = {
    //   responseJSON: {
    //     telemetry_files: [
    //       { id: 17, filename: 'telemetry_2020-09-21 00-00-00.log', first_msg_time: '2020-09-21T00:00:00.302000-0400', last_msg_time: '2020-09-21T15:20:28.804000-0400' },
    //       { id: 53, filename: 'telemetry_2020-09-21 15-21-12.log', first_msg_time: '2020-09-21T15:21:12.624000-0400', last_msg_time: '2020-09-21T19:40:58.164000-0400' }
    //     ]
    //   }
    // };

    // this.__external_list_response(temporary_response);
  }

  clear_fragments()
  {
    super.clear_fragments();
    this.recording_telemetry_manager.clear();
  }

  __external_list_response(response)
  {
    console.log(response);

    if (response.responseJSON && response.responseJSON.telemetry_files)
    {
      let selected_camera = PhysicalCameraManager.selected_camera;
      let gps_enabled = selected_camera ? selected_camera.gps_enabled : undefined;

      if (gps_enabled && gps_enabled.toLowerCase() === 'yes')
      {
        for (let i = 0; i < response.responseJSON.telemetry_files.length; i++)
        {
          const telemetry_file = response.responseJSON.telemetry_files[i];

          this.recording_telemetry_manager.add_recording_date_range(
            telemetry_file.id,
            telemetry_file.first_msg_time,
            telemetry_file.last_msg_time,
            telemetry_file.filename
          );
        }

        let recording_telemetries = this.recording_telemetry_manager.get_telemetries_on_date(this.current_date);

        for (let i = 0; i < recording_telemetries.length; i++)
        {
          const recording_telemetry = recording_telemetries[i];

          let day_start_time_ms = recording_telemetry.get_local_start_date_in_ms();
          let day_end_time_ms   = recording_telemetry.get_local_start_date_in_ms() + (recording_telemetry.duration_in_seconds_from_start * 1000);

          this.add_fragment(
            recording_telemetry,
            day_start_time_ms,
            day_end_time_ms
          );
        }
      }
    }
  }
}
