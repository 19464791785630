import FragmentManager from './FragmentManager';

import { Input } from 'ohzi-core';
import { MathUtilities } from 'ohzi-core';
import DetailedPlaybackBar from './DetailedPlaybackBar';
import TelemetryFragmentManager from './TelemetryFragmentManager';
import RecordingTelemetryManager from '../RecordingTelemetryManager';

// This represents Playback Bar UI of the PlayerView

/**
 * @class PlaybackBar
 * @description Handles the functionallity of playback bar on Recordings mode or Archive mode. 
 * This bar is hidden on Live mode. 
 */
export default class PlaybackBar
{
  constructor()
  {
    this.start_ms = 0;
    this.end_ms = 86400000;

    this.fragment_manager = undefined;
    this.time_scale = 1;
    this.elapsed_recording_time = 0;
    this.cursor_ms = 0;
    this.progress_ms = 0;
    this.time_label_ms = 0;
  }

  start()
  {
    this.playback_container = document.querySelector('.player__playback-container');

    this.playback_bar = $('.player__playback-bar');
    this.fragment_container = $('.player__playback-fragments');
    this.telemetry_fragment_container = $('.player__playback-telemetry-fragments');
    this.time_label      = $('.player__playback-time-label');
    this.time_label_elem = this.time_label[0];
    this.start_time = $('.player__start-time');
    this.end_time = $('.player__end-time');

    this.cursor_elem = document.querySelector('.player__playback-cursor');
    this.playback_bar_progress = document.querySelector('.player__playback-bar-progress');
    this.player_star_time_elem = document.querySelector('.player__start-time');
    this.player_end_time_elem = document.querySelector('.player__end-time');

    this.fragment_manager = new FragmentManager(this.playback_bar, this.fragment_container);

    this.telemetry_fragment_manager = new TelemetryFragmentManager(this.playback_bar, this.telemetry_fragment_container);

    //DetailedPlaybackBar is used by DetailedRecordingMode.js
    this.detailed_playback_bar = new DetailedPlaybackBar(this);
    this.detailed_playback_bar.start();

    this.recalculate_bounding_client();
  }

  update()
  {
    this.recalculate_bounding_client();

    this.detailed_playback_bar.update();
    this.fragment_manager.update_fragments_in_range(this.start_ms, this.end_ms);
    this.telemetry_fragment_manager.update_fragments_in_range(this.start_ms, this.end_ms);
  }

  set_range(start_ms, end_ms)
  {
    this.start_ms = start_ms;
    this.end_ms = end_ms;

    this.set_time_label_to_element(this.player_star_time_elem, this.start_ms);
    this.set_time_label_to_element(this.player_end_time_elem, this.end_ms);
  }

  set_elapsed_time(time)
  {
    this.elapsed_time = time;
    this.elapsed_time = THREE.Math.clamp(this.elapsed_time, this.start_ms, this.end_ms);
  }

  set_cursor_ms(cursor_ms)
  {
    this.cursor_ms = cursor_ms;
    this.__set_translate(this.cursor_elem, this.__ms_to_px(this.cursor_ms));
  }

  set_progress_ms(progres_ms)
  {
    this.progress_ms = progres_ms;

    let normalized_progress_px = this.__ms_to_px(this.progress_ms) / this.playback_bar_rect.width;
    this.__set_scale(this.playback_bar_progress, normalized_progress_px);
  }

  set_time_label_ms(time_label_ms)
  {
    this.time_label_ms = time_label_ms;

    this.__set_translate(this.time_label_elem, this.__ms_to_px(this.time_label_ms));
    this.set_time_label_to_element(this.time_label_elem, this.time_label_ms);
  }

  // show_time_label()
  // {
  //   this.time_label.removeClass('hidden');
  // }

  // hide_time_label()
  // {
  //   this.time_label.addClass('hidden');
  // }

  show_range_labels()
  {
    this.start_time.removeClass('hidden');
    this.end_time.removeClass('hidden');
  }

  hide_range_labels()
  {
    this.start_time.addClass('hidden');
    this.end_time.addClass('hidden');
  }

  // Get the first fragment that represents the first recording of a specific day.
  get_first_fragment()
  {
    return this.fragment_manager.get_first_fragment();
  }

  // Get the current playing fragment
  get_current_fragment_playing()
  {
    return this.fragment_manager.get_current_fragment_playing();
  }

  set_current_fragment_playing(current_fragment_playing)
  {
    this.fragment_manager.set_current_fragment_playing(current_fragment_playing);
  }

  add_fragment(recording_id, recording_start_offset, start_time_ms, end_time_ms, file_name, timezone)
  {
    return this.fragment_manager.add_fragment(recording_id, recording_start_offset, start_time_ms, end_time_ms, file_name, timezone);
  }

  add_telemetry_fragments(current_date)
  {
    this.telemetry_fragment_manager.add_fragments(current_date);
  }

  clean_fragments()
  {
    this.fragment_manager.clear_fragments();
    this.telemetry_fragment_manager.clear_fragments();
  }

  get_fragment_contained_at_ms(ms)
  {
    return this.fragment_manager.get_fragment_containing(ms);
  }

  show()
  {
    this.playback_container.classList.remove('hidden');
  }

  hide()
  {
    this.playback_container.classList.add('hidden');
  }

  // Get updated size of the playback bar line. This is necessary when the size of the player is changed.
  // For intances on fullscreen or by rezising the browser window
  recalculate_bounding_client()
  {
    this.playback_bar_rect = this.playback_bar[0].getBoundingClientRect();
  }

  // Update the time label of the playback bar
  set_time_label_to_element(element, time)
  {
    let hours = time / 1000 / 60 / 60;
    let minutes = (hours - Math.floor(hours)) * 60;
    let seconds = (minutes - Math.floor(minutes)) * 60;

    hours = ('0' + Math.floor(hours)).slice(-2);
    minutes = ('0' + Math.floor(minutes)).slice(-2);
    seconds = ('0' + Math.floor(seconds)).slice(-2);

    let new_time = `${hours}:${minutes}:${seconds}`;

    if (new_time !== element.innerText)
    {
      element.innerText = new_time;
    }
  }

  is_mouse_over()
  {
    return Input.mouse_is_within_bounds(this.playback_container.getBoundingClientRect());
  }

  // Convert milliseconds into pixeles
  __ms_to_px(ms)
  {
    // The linear map function maps a value x from range A to range B
    return MathUtilities.linear_map(ms, this.start_ms, this.end_ms, 0, this.playback_bar_rect.width);
  }

  // Get the position of the mouse in miliseconds
  get_mouse_ms()
  {
    // The linear map function maps a value x from range A to range B
    return MathUtilities.linear_map(this.get_mouse_px(), 0, this.playback_bar_rect.width, this.start_ms, this.end_ms);
  }

  // Get the position of the mouse in pixels
  get_mouse_px()
  {
    let position = Input.mouse_pos.x - this.playback_bar_rect.left;
    return THREE.Math.clamp(position, 0, this.playback_bar_rect.width);
  }

  get_normalized_mouse_px()
  {
    return this.get_mouse_px() / this.playback_bar_rect.width;
  }

  __set_translate(element, px)
  {
    element.style.transform = `translate3d(${px}px, 0, 0)`;
  }

  __set_scale(element, scale)
  {
    element.style.transform = `scaleX(${scale})`;
  }
}
