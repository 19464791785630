import MapMarker from './MapMarker';
import PhysicalCameraManager from './PhysicalCameraManager';

// This class is a wrapper for a cesium marker
export default class PlaneMapMarker extends MapMarker
{
  constructor(text, lon, lat, alt, map_zoom, scale, opacity, is_inverted, sphere)
  {
    super(text, lon, lat, alt, map_zoom, scale, opacity, is_inverted, sphere);

    this.last_video_width = 0;
    this.last_video_height = 0;
  }

  update()
  {
    this.__update_plane_resolution();
  }

  __update_plane_resolution()
  {
    if (this.cesium_marker_plane)
    {
      if (this.cesium_marker_plane.appearance.material.uniforms.image)
      {
        if (PhysicalCameraManager.selected_camera)
        {
          if (this.last_video_width !== PhysicalCameraManager.selected_camera.stream.html_video.container.videoWidth &&
              this.last_video_height !== PhysicalCameraManager.selected_camera.stream.html_video.container.videoHeight)
          {
            // console.log('[PlaneMapMarker:__update_plane_resolution]');
            this.set_image_url(PhysicalCameraManager.selected_camera.stream.html_video.container);
            // PhysicalCameraManager.selected_camera.fly_to_camera_marker();

            this.last_video_width = PhysicalCameraManager.selected_camera.stream.html_video.container.videoWidth;
            this.last_video_height = PhysicalCameraManager.selected_camera.stream.html_video.container.videoHeight;
          }
        }
      }
    }
  }
}
