import MenuCameraStatusState from './MenuCameraStatusState';
import WaitingForStreaming from './WaitingForStreaming';
import ReceivingConfigurationUpdate from './ReceivingConfigurationUpdate';

export default class Streaming extends MenuCameraStatusState
{
  constructor()
  {
    super();
  }

  on_enter(menu_camera_status)
  {
    menu_camera_status.set_rec_icon_color('#00FF00');
  }

  update(menu_camera_status)
  {
    if (!menu_camera_status.stream.is_streaming)
    {
      menu_camera_status.set_state(new WaitingForStreaming());
    }
  }

  on_config_received(menu_camera_status)
  {
    menu_camera_status.set_state(new ReceivingConfigurationUpdate());
  }
}
