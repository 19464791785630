import HTMLVideo from '/js/components/html_components/common/HTMLVideo';
import HTMLCanvas from '/js/components/html_components/common/HTMLCanvas';
import WebRtcStream from '/js/components/WebRtcStream';
import { Configuration } from 'ohzi-core';

import MenuCameraStatus from './MenuCameraStatus';
import HTMLImage from '../common/HTMLImage';

// This class stores the PhysicalCamera and WebRTC stream of a camera in the menu
export default class MenuCameraWebRtc
{
  constructor(physical_camera, menu_camera_element)
  {
    this.physical_camera = physical_camera;
    // use the getter to access this
    this.__html_element = menu_camera_element;
    this.__html_element[0].setAttribute('onclick', 'app.menu_view.select_camera(this, event)');
    this.__html_element[0].setAttribute('data-name', physical_camera.name);

    //this.stream = new AxisCameraStream(this.create_html_video_element());
    this.stream = new WebRtcStream(this.create_html_video_element(), physical_camera.stream.stream);
    this.stream.set_profile('thumb');
    this.stream.set_endpoint(this.physical_camera);

    let video_container = this.__html_element.find('.menu__cameras-camera-image-video-container')[0];
    this.stream.html_video.append_to(video_container);

    this.__html_element.find('.menu__cameras-camera-title').text(physical_camera.name);
    this.rec_icon_circle = this.__html_element.find('.menu__cameras-camera-rec-icon').find('circle');
    
    // Assign action to switch
    // TODO: Change tandem and map_view switch to be hidden by default. Then add __show_elem to enable the switch if required
    this.tandem_switch_el = menu_camera_element.find('.menu__cameras-camera-tandem');
    this.tandem_switch = physical_camera.tandem_enabled;
   
    this.tandem_switch_el[0].setAttribute('onclick', 'app.menu_view.toggle_tandem_mode(this, event)');
    this.tandem_switch_el[0].setAttribute('data-name', physical_camera.name);
    
    //console.log(`[MenuCamera] Camera '${physical_camera.name}' Lens: ${physical_camera.lens_type} Type: ${physical_camera.type}`);
      
    this.__hide_elem(this.tandem_switch_el);
    
    // Assign action to map_view switch
    this.map_view_switch_el = menu_camera_element.find('.menu__cameras-camera-map_view');
    this.map_view_switch = physical_camera.tandem_enabled;
   
    // Disable 'map view' feature for production release
    //this.map_view_switch_el[0].setAttribute('onclick', 'app.menu_view.toggle_map_view_mode(this, event)');
    //this.map_view_switch_el[0].setAttribute('data-name', physical_camera.name);

    //if (physical_camera.cam_type !== 'phone' && physical_camera.orientation !== 'mobile') {
      this.__hide_elem(this.map_view_switch_el);
    //}
    
    this.status = new MenuCameraStatus(this);
  }

  set_rec_icon_color(color)
  {
    this.rec_icon_circle.css('fill', color);
  }

  create_html_video_element()
  {
    let html_video = undefined;
    let video_class_name = 'menu__cameras-camera-image-video';
    if (Configuration.is_ios)
    {
      html_video = new HTMLCanvas(video_class_name);
    }
    else
    {
      html_video = new HTMLVideo(video_class_name, true);
    }
    return html_video;
  }

  // Called by MenuView
  start_streaming(should_freeze_after_start)
  {
    console.log(`[MenuCameraWebRtc:start_streaming]`);
    let callback = should_freeze_after_start ? this.__freeze_image.bind(this) : undefined;
    //let callback = undefined;
    this.stream.start_streaming(callback);
  }

  play()
  {
    this.stream.play();
  }

  pause()
  {
    this.stream.pause();
  }

  stop()
  {
    console.log(`[MenuCameraWebRtc:stop]`);
    this.stream.stop();
  }

  config_updated()
  {
    this.status.config_updated(this);
  }

  append_to(container)
  {
    container.append(this.__html_element);
  }

  update()
  {
    this.status.update(this);
  }
  
  show()
  {
    this.__show_elem(this.__html_element);
  }

  hide()
  {
    this.__hide_elem(this.__html_element);
  }

  __freeze_image()
  {
    this.stream.pause();
    this.stream.__stop_video_pipeline();
  }

  __toggle_switch_elem(elem, should_activate)
  {
    if (should_activate)
    {
      $(elem).addClass('active');
    }
    else
    {
      $(elem).removeClass('active');
    }
  }

  __hide_elem(elem)
  {
    $(elem).addClass('hidden');
  }

  __show_elem(elem)
  {
    console.log(`__show_elem`);
    console.dir(elem);
    $(elem).removeClass('hidden');
  }
}
