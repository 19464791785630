import MenuCameraStatusState from './MenuCameraStatusState';
import Streaming from './Streaming';
import ReceivingConfigurationUpdate from './ReceivingConfigurationUpdate';

export default class WaitingForStreaming extends MenuCameraStatusState
{
  constructor()
  {
    super();
  }

  on_enter(menu_camera_status)
  {
    menu_camera_status.set_rec_icon_color('#FF0000');
  }

  update(menu_camera_status)
  {
    if (menu_camera_status.stream.is_streaming)
    {
      menu_camera_status.set_state(new Streaming());
    }
  }

  on_config_received(menu_camera_status)
  {
    menu_camera_status.set_state(new ReceivingConfigurationUpdate());
  }
}
