import MenuViewMode from './MenuViewMode';
import PhysicalCameraManager from '../../PhysicalCameraManager';
import MenuCameraManager from '../../MenuCameraManager';

// This represents the Live mode of the MenuView
/**
 * @class LiveMode
 * @description User is able to see his Live Cameras.Get activated when Live tab is clicked.
 * @function on_tab_selected() - Get called when user has clicked the tab. (called by MenuView).
 * @function on_player_hide() -  Get called when we want to hide the player. (called by PlayerContainer)
 * @function is_valid_camera() -  It works as filter for whcich camera to display in the menu view.Called by menuView.js
 * 
 */
export default class LiveMode extends MenuViewMode
{
  constructor()
  {
    super('live');
  }

  on_tab_selected(menu_view)
  {
    menu_view.fill_menu(0);
  }

  is_valid_camera(physical_camera)
  {
    return (physical_camera.bodyworn !== 'yes' || physical_camera.is_live_stream_available());
  }

  on_camera_selected(menu_view)
  {
    // Rewrite recording_id to undefined, to get the live streaming from the physical camera
    PhysicalCameraManager.selected_camera.stream.set_recording_data(undefined, 0);
    PhysicalCameraManager.selected_camera.stream.start_streaming(menu_view.app.player_view.play.bind(menu_view.app.player_view));

    // Set the player view into live mode
    menu_view.app.player_view.set_live_mode();
    menu_view.app.player_view.extract_recording_view.hide_open_button();

    menu_view.app.player_container.show();
    menu_view.close();

    if (PhysicalCameraManager.selected_camera.cam_type === 'phone')
    {
      PhysicalCameraManager.selected_camera.fly_on_valid_telemetry = true;
    }
  }

  on_tandem_selected(menu_view)
  {
    if (!PhysicalCameraManager.selected_camera_tandem)
    {
      return;
    }
    PhysicalCameraManager.selected_camera_tandem.plane_stream.set_recording_data(undefined, 0);
    PhysicalCameraManager.selected_camera_tandem.plane_stream.start_streaming(menu_view.app.player_view.tandem_plane_view_play.bind(PhysicalCameraManager, PhysicalCameraManager.selected_camera_tandem));
    
    menu_view.close();
  }
}
