import PhysicalCameraManager from '/js/components/PhysicalCameraManager';

class BrowserEventHandler
{
  constructor()
  {
    this.player_view = undefined;
  }

  start(player_view)
  {
    this.player_view = player_view;

    // When browser window is reshown set video to latest frames
    document.addEventListener('visibilitychange', this.on_visibilitychange.bind(this));
  }

  on_visibilitychange(event)
  {
    if (document.visibilityState === 'visible')
    {
      this.player_view.play();
      this.__sync_player_video();
      if (PhysicalCameraManager.selected_camera_tandem)
      {
        let tandem_vid = PhysicalCameraManager.selected_camera_tandem.plane_stream.html_video.container;
        this.__sync_video(tandem_vid);
      }
    }
    else
    {
      this.player_view.pause();
    }
  }

  __sync_player_video()
  {
    let vid = this.player_view.html_video.container;

    if (vid && vid.buffered && vid.buffered.length > 0 && vid.buffered.end && vid.duration)
    {
      // let currentTime = vid.currentTime ;
      let bufferedTime = vid.buffered.end(0);
      let lagTime = bufferedTime - vid.currentTime;

      if ((lagTime > 2.0) && !vid.paused)
      {
        vid.currentTime = vid.buffered.end(0);
        this.player_view.playback_time = this.player_view.initial_playback_time + (vid.currentTime * 1000);
      }
    }
  }

  __sync_video(vid)
  {
    if (!vid)
      return;
    
    if (vid && vid.buffered && vid.buffered.length > 0 && vid.buffered.end && vid.duration)
    {
      let bufferedTime = vid.buffered.end(0);
      let lagTime = bufferedTime - vid.currentTime;

      if ((lagTime > 2.0) && !vid.paused)
      {
        vid.currentTime = vid.buffered.end(0);
        //this.player_view.playback_time = this.player_view.initial_playback_time + (vid.currentTime * 1000);
      }
    }
  }
}

export default new BrowserEventHandler();
