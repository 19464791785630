import ApplicationView from './common/ApplicationView';
// import MenuViewMode from '/js/components/html_components/menu_view_mode/MenuViewMode';
import MenuView from '/js/components/html_components/MenuView';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import MenuCameraManager from '/js/components/MenuCameraManager';
import RoleManager from '/js/components/RoleManager';
// import CameraTargetUtilities from '../CameraTargetUtilities';
// import PlayerSettings from '../PlayerSettings';

// This handles the websocket messages
export default class WebRtcView extends ApplicationView
{
  // constructor(app, host, type)
  constructor(app)
  {
    super('webrtc', $('.menu__webrtc'));
    // this.host = host;
    // this.type = type;
    this.app = app;

    this.janusClients = {};
    this.feeds = [];

    this.allStreams = [];
    this.hasStreamsWithNoPublisher = false;
    this.updateTime = null;
    this.updateGap = 10000;

    // initJanus();

    this.token = null;
    this.streams = {};
    this.gotToken = false;
    this.numberAttempToConnect = 0;

    // this.box = document.querySelector('.stream-container');
    this.box = undefined;

    this.socket = undefined;
  }

  start()
  {
    console.log('WebRtcView:start]');

    if (!RoleManager.logged_user.nodeUrl || !RoleManager.logged_user.stunUrl)
    {
      console.warn('Current logged user doesn\'t have valid stunUrl or nodeUrl to access WebRTC');
      return;
    }

    webrtc_set_data_callback(this.__on_webrtc_stream_data, this);
    webrtc_set_remove_stream_callback(this.__on_webrtc_stream_removed, this);
    initWebRTC(this.__on_webrtc_stream_connected, this,
      RoleManager.logged_user.nodeUrl,
      RoleManager.logged_user.stunUrl
    );

    let janus = initJanus()
      .then(function()
      {
        console.log('[WebRtcView:start] janus init successfull');
        console.dir(janus);
      })
      .catch(function(error)
      {
        console.log('[WebRtcView:start] janus init error');
        console.dir(error);
      });
  }

  add_camera(physical_camera)
  {
    console.log('[WebRtcView:add_camera]');
    let menu_camera = MenuCameraManager.add_camera(physical_camera);
    this.app.menu_view.fill_menu(0);

    // menu_camera.stream.webrtc_janus = physical_camera.stream.webrtc_janus;
    menu_camera.stream.webrtc_janus = physical_camera.stream.stream;
    menu_camera.stream.webrtc_stream = physical_camera.stream.webrtc_stream;

    // console.dir(menu_camera);
    // if (menu_camera)
    // {
    //   if (this.app.menu_options_view.show_thumbnail_video)
    //   {
    //     menu_camera.start_streaming();
    //   }

    //   if (this.app.menu_options_view.show_thumbnail_image)
    //   {
    //     menu_camera.start_streaming(true);
    //   }
    //   if (physical_camera.lens_type === 'flat')
    //   {
    //     this.hide_camera_fisheye_frame(physical_camera.name);
    //   }
    // }
    return menu_camera;
  }

  hide_camera_fisheye_frame(camera_name)
  {
    // console.log(`[WebRtcView:hide_camera_fisheye_frame]`);
    let camera_el = $(`.menu__cameras-camera[data-name='${camera_name}']`);
    // console.dir(camera_el);

    $(camera_el).find('.menu__cameras-camera-image-img').addClass('invisible');
  }

  // TODO: Why is this not getting bound to this correctly from webrtc.js?
  /*
  WebRTC Menu and Main Camera use the same streaming source (as axis cameras create new RTSP streams)
  janus = Janus object
  webrtc_item = Janus stream data
  webrtc_stream = Standard MediaStream class
  */
  __on_webrtc_stream_connected(_this, janus, webrtc_item, webrtc_stream)
  {
    console.log('[__on_webrtc_stream_connected]');

    if (!webrtc_item || !webrtc_item.streamer)
    {
      console.warn('[__on_webrtc_stream_connected] webrtc_item.streamer. Exiting');
      return;
    }

    let height = 0;
    let width = 0;

    let name      = '' + webrtc_item.streamer.name;
    let group     = '' + webrtc_item.group;

    if (RoleManager.logged_user.groups.indexOf(group) <= -1)
    {
      // Current user doesn't have access to this group so don't accept video stream
      return;
    }

    let camera    = PhysicalCameraManager.get_by_name(name);

    if (camera)
    {
      const track = webrtc_stream.getVideoTracks()[0];
      if (track)
      {
        height = track.getSettings().height;
        width = track.getSettings().width;
      }

      // Redefine as we use this to detect if camera has a live janus webrtc stream
      camera.stream.webrtc_janus = janus;

      // If camera is already receiving video
      if (camera.stream.is_streaming)
      {
        // camera.map_marker.cesium_marker_plane.clear_material();
        camera.map_marker.cesium_marker_plane.appearance.material.uniforms.image = undefined;
        camera.map_marker.set_video_resolution(height, width);
        let videoElement = camera.stream.html_video.container;
        // console.dir(videoElement);
        camera.map_marker.set_image_url(camera.stream.html_video.container);
      }
      else
      {
        // Assign Janus MediaStream object to the camera stream
        camera.stream.stream = webrtc_stream;

        if (height)
        {
          camera.video_height = height;
          camera.video_width = width;
        }

        camera.stream.webrtc_janus = janus;
        camera.plane_resolution_set = false;

        // camera.stream.start_streaming(_this.app.player_view.webrtc_plane_view_play.bind(_this.app.player_view, camera));

        // menu_camera.stream.webrtc_janus = janus;
      }

      let menu_camera = MenuCameraManager.get_by_name(name);

      if (!menu_camera)
      {
        console.log('[__on_webrtc_stream_connected] Adding menu camera');
        _this.add_camera(camera);
      }

      return;
    }

    let cam_data = {};
    cam_data.cam_type     = 'phone';
    // cam_data.bodyworn     = 'yes';
    cam_data.name         = name;
    cam_data.id           = webrtc_item.streamer.id;
    cam_data.user_groups  = RoleManager.logged_user.groups.toString();
    cam_data.roll         = 0.0;
    cam_data.pitch        = 0.0;
    cam_data.yaw          = 0.0;
    cam_data.cam_lat      = 0.0;
    cam_data.cam_lon      = 0.0;
    cam_data.cam_alt      = 50.0;

    camera = PhysicalCameraManager.add_camera(cam_data, webrtc_stream);

    // camera.webrtc_stream = webrtc_stream;  // TODO: Only used for getting height and width in PhyscailCameraMobile. Can get it from camera.stream.stream

    if (height)
    {
      camera.video_height = height;
      camera.video_width = width;
    }

    camera.stream.webrtc_janus = janus;
    camera.plane_resolution_set = false;

    if (camera)
    {
      // TODO: _this is temp until can get 'this' working on callback
      // _this.app.menu_view.add_camera(physical_camera);
      let menu_camera = _this.add_camera(camera);

      if (menu_camera)
      {
        // menu_camera.stream.webrtc_janus = janus;
        // menu_camera.stream.webrtc_stream = webrtc_stream;
      }

      // This will stream to the main player which we don't want to nessacery do
      // camera.stream.start_streaming(_this.app.player_view.webrtc_plane_view_play.bind(_this.app.player_view, camera));

      // Show arrow and text only on map
      camera.show_map_marker(true);
    }
  }

  __on_webrtc_stream_data(webrtc_data)
  {
    let data = JSON.parse(webrtc_data);

    PhysicalCameraManager.update_camera(data.name, data);
    MenuCameraManager.camera_updated(data.name);
  }

  // TODO: Why is this not getting bound to this correctly?
  // __on_webrtc_stream_removed(data, data2)
  __on_webrtc_stream_removed(_this, data)
  {
    let camera = PhysicalCameraManager.get_by_name(data.name);

    if (camera)
    {
      if (camera === PhysicalCameraManager.selected_camera)
      {
        _this.app.player_container.close();
        camera.stream.stop();
      }

      camera.hide_map_marker(false);

      camera.plane_resolution_set = false;
      if (camera._id)
      {
        // Camera exists from server database so keep, just undefine the stream so it is hidden on live view.
        camera.stream.webrtc_janus = undefined;
      }
      else
      {
        PhysicalCameraManager.remove_camera(camera);
      }
    }

    _this.app.menu_view.fill_menu(0);
  }
}
