import { Configuration } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';

// const { pipelines } = window.mediaStreamLibrary;

// This handles the communication with a mobile device using WebRtc
export default class WebRtcStream
{
  constructor(html_video, stream)
  {
    this.html_video     = html_video;   // HTMLVideo class

    this.stream         = stream;       // Standard MediaStream class from WebRTC

    this.webrtc_janus  = undefined;  //

    this.user = undefined;
    this.password = undefined;
    this.host = undefined;
    this.recording_id = undefined;
    this.profile = undefined;
    this.play_offset = 0;

    this.is_streaming = false;
  }

  // Called when the camera settings are updated
  set_endpoint(camera)
  {
    // this.__stop_video_pipeline();
    this.user = camera.username;
    this.password = camera.password;
    this.host = camera.live_ip;
    this.resolution = camera.live_resolution;
  }

  // Called by MenuView and PlayerView.
  //
  // play_offset defines how many seconds of offset do you want when playing recording.
  // This is how we play a specific time of a specific recording.
  //
  // If play_offset is 0, the streaming of the recording starts
  // at the beginning of the recording
  set_recording_data(recording_id, play_offset)
  {
    this.recording_id = recording_id;
    this.play_offset  = play_offset;
  }

  set_profile(profile)
  {
    this.profile = profile;
  }

  set_resolution(resolution)
  {
    this.resolution = resolution;
  }

  // Called by MenuView and PlayerView
  start_streaming(on_video_ready)
  {
    // this.__stop_video_pipeline();
    this.__start_video_pipeline(this.__on_pipeline_ready.bind(this, on_video_ready));
  }

  play()
  {
    this.html_video.play();

    // this.__webrtc_enable_video();
  }

  pause()
  {
    this.html_video.pause();

    // this.__webrtc_disable_video();
  }

  stop()
  {
    console.log('WebRtcStream:stop');
    this.html_video.stop();
    this.__stop_video_pipeline();
  }

  //
  __start_video_pipeline(on_ready_callback)
  {
    // console.log(`[WebRtcStream:__start_video_pipeline]`);
    // console.dir(this);
    // console.dir(this.stream);
    // console.dir(this.html_video.container);

    if (!this.html_video.container.srcObject)
    {
      // console.log(`[WebRtcStream:__start_video_pipeline] srcObject = empty we can attach media`);
    }
    // this.html_video.container.srcObject = this.stream;
    Janus.attachMediaStream(this.html_video.container, this.stream);

    // TODO: Temp solution
    // Delay play callback to allow player to load up
    setTimeout(function()
    {
      // console.log(`[WebRtcStream:__start_video_pipeline] Delayed call to ready callback`);
      on_ready_callback();
    }, 1);

    this.is_streaming = true;
  }

  __on_pipeline_ready(callback)
  {
    if (callback)
    {
      callback();
    }
  }

  __stop_video_pipeline()
  {
    this.is_streaming = false;
    // this.__webrtc_disable_video();

    // console.log(`[WebRtcStream:__stop_video_pipeline]`);
    // console.dir(this.webrtc_janus);

    if (this.webrtc_janus)
    {
      // this.webrtc_janus.destroy();
    }
  }

  /*
  https://stackoverflow.com/questions/35857576/webrtc-pause-and-resume-stream
Use getTracks()[0].enabled = false instead. To unpause getTracks()[0].enabled = true.

This will replace your video with black, and your audio with silence.

video1.srcObject.getTracks().forEach(t => t.enabled = !t.enabled);

TODO: Disable/enable audio track also. Do a for loop for getTracks
  */
  __webrtc_disable_video()
  {
    // console.info('[WebRtcStream:__webrtc_disable_video]');
    // console.dir(this);
    // console.dir(this.html_video.container);

    if (this.html_video.container.srcObject)
    {
      this.html_video.container.srcObject.getTracks().forEach(function(track)
      {
        // console.dir(track);
        // track.stop();
        track.enabled = false;
      });

      // this.html_video.container.srcObject.getVideoTracks()[0].enabled = false;
    }
  }

  __webrtc_enable_video()
  {
    // console.info('[WebRtcStream:__webrtc_enable_video]');
    // console.dir(this);

    if (this.html_video.container.srcObject)
    {
      // this.html_video.container.srcObject.getVideoTracks()[0].enabled = true;

      this.html_video.container.srcObject.getTracks().forEach(function(track)
      {
        // console.dir(track);
        // track.stop();
        track.enabled = true;
      });
    }
  }

  __get_websocket_ip()
  {
    return ResourceContainer.get_resource('config').cameras_websocket_ip;
  }

  get_screenshot_url()
  {
    let scale = 1;
    const canvas = document.createElement('canvas');
    canvas.width = $(this.html_video.container).width() * scale;
    canvas.height = $(this.html_video.container).height() * scale;

    canvas.getContext('2d').drawImage(this.html_video.container, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL();
  }
}
