import { ObjectUtilities } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';

import FisheyeSphere from '/js/components/FisheyeSphere';
import TexturePlane from '/js/components/TexturePlane';
import MapMarker from '/js/components/MapMarker';
import MapTargetMarker from '/js/components/MapTargetMarker';

import PhysicalCamera from './PhysicalCamera';
import PlayerSettings from './PlayerSettings';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';

// This represents the camera containing the config variables used for the application
export default class PhysicalCameraFlat extends PhysicalCamera
{
  constructor(params, stream, plane_stream, texture)
  {
    super(params, stream, texture);

    this.cam_type  = 'axis_flat';
    this.lens_type = 'flat';
    this.sphere = false;

    this.plane_stream   = plane_stream;

    // this.fisheye_sphere         = new FisheyeSphere();
    // TODO: Change fisheye_sphere name to map_geometery or similar
    this.fisheye_sphere         = new TexturePlane();
    this.map_marker             = new MapMarker(
      this.name,
      this.cam_lon,
      this.cam_lat,
      this.default_map_marker_alt,
      // this.cam_alt,
      this.map_zoom,
      this.marker_scale,
      this.marker_opacity,
      this.use_upper_hemisphere,
      this.sphere
    );

    this.ptz_pan                = 0;
    this.ptz_tilt               = 0;

    this.fisheye_sphere.set_texture(texture);
    this.update_params(params);
  }

  start()
  {}

  update_params()
  {
    // Adjust marker plane yaw/pitch to compensate for camera rpy and target angle relative to camera.
    // let pitch = this.pitch + this.image_tilt;
    // let yaw = this.yaw + this.image_pan;
    let pitch = this.pitch + this.ptz_tilt;
    let yaw = this.yaw + this.ptz_pan;
    if (yaw > 360)
    {
      yaw -= 360;
    }

    // this.image_pan = yaw;
    // this.image_tilt = pitch;

    // console.log(`[PhysicalCameraFlat:update_params] yaw: ${yaw}, roll: ${this.roll}, pitch: ${pitch}, ptz_tilt: ${this.ptz_tilt}, image_tilt: ${this.image_tilt}`);

    this.map_marker.set_position(this.cam_lon, this.cam_lat, this.default_map_marker_alt);
    // this.map_marker.set_position(this.cam_lon, this.cam_lat, this.cam_alt);
    // this.map_marker.set_rotation(this.yaw, this.roll, this.pitch);
    this.map_marker.set_rotation(yaw, this.roll, pitch);
    this.map_marker.set_name(this.name);
    this.map_marker.set_scale(this.marker_scale);
    this.map_marker.set_opacity(this.marker_opacity);
    this.map_marker.set_inverted(this.use_upper_hemisphere);
    this.map_marker.update_marker_color();

    // if (PlayerSettings.lock_view)
    if (PlayerSettings.lock_view && 
      (this === PhysicalCameraManager.selected_camera || this === PhysicalCameraManager.selected_camera_tandem))
    {
      this.map_marker.set_view_to_plane(this.map_zoom, true);
    }

    this.map_target_marker.set_position(this.tgt_lon, this.tgt_lat, this.tgt_alt, this.tgt_distance);
    this.map_target_marker.set_name(this.tgt_name);

    this.__update_name_in_menu();

    this.stream.set_endpoint(this);
    this.stream.set_profile(this.live_profile);

    this.plane_stream.set_endpoint(this);
  }
}
