import { ObjectUtilities } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';

import FisheyeSphere from '/js/components/FisheyeSphere';
import MapMarker from '/js/components/MapMarker';
import MapTargetMarker from '/js/components/MapTargetMarker';

import PhysicalCamera from './PhysicalCamera';

// This represents the camera containing the config variables used for the application
/**
 * @class PhysicalCameraFisheye
 * @description Handles the fishey camera.
 * @function update_params() - Updates Camera with new inputs that are received from server by right clicking on the map.(called by PhysicalCameraManager)
 * 
 */
export default class PhysicalCameraFisheye extends PhysicalCamera
{
  //stream is type of class AxisCameraStream. More details at AxisCameraStream.js
  constructor(params, stream, texture)
  {
    super(params, stream, texture);

    this.cam_type  = 'axis_fisheye';
    this.lens_type = 'fisheye';
    this.sphere = true;

    this.fisheye_sphere         = new FisheyeSphere();
    this.map_marker             = new MapMarker(
      this.name,
      this.cam_lon,
      this.cam_lat,
      this.default_map_marker_alt,
      // this.cam_alt,
      this.map_zoom,
      this.marker_scale,
      this.marker_opacity,
      this.use_upper_hemisphere,
      this.sphere
    );

    this.fisheye_sphere.set_texture(texture);
    this.update_params(params);
  }

  start()
  {}

  update_params()
  {
    this.fisheye_sphere.set_fov_and_use_upper_hemisphere(this.fov, this.use_upper_hemisphere);
    this.fisheye_sphere.set_rotation(this.yaw, this.roll, this.pitch);

    this.map_marker.set_position(this.cam_lon, this.cam_lat, this.default_map_marker_alt);
    // this.map_marker.set_position(this.cam_lon, this.cam_lat, this.cam_alt);
    this.map_marker.set_rotation(this.yaw, this.roll, this.pitch);
    this.map_marker.set_name(this.name);
    this.map_marker.set_scale(this.marker_scale);
    this.map_marker.set_opacity(this.marker_opacity);
    this.map_marker.set_inverted(this.use_upper_hemisphere);
    this.map_marker.update_marker_color();


    this.map_target_marker.set_position(this.tgt_lon, this.tgt_lat, this.tgt_alt, this.tgt_distance);
    this.map_target_marker.set_name(this.tgt_name);

    this.__update_name_in_menu();

    this.stream.set_endpoint(this);
    this.stream.set_profile(this.live_profile);
  }
}
