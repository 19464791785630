
export default class HTMLImage
{
  constructor(class_name)
  {
    this.container = document.createElement('img');
    this.container.className = `${class_name}`;
  }

  append_to(parent)
  {
    parent.appendChild(this.container);
  }

  play()
  {
    console.error('canvas play');
  }

  pause()
  {
    console.error('canvas pause');
  }

  stop()
  {
    console.error('canvas stop');
  }

  is_image_showing()
  {
    return true;
  }

  clear_buffer()
  {
    console.error('canvas clear buffer');
  }
}
