import { ArrayUtilities } from 'ohzi-core';

import MenuCamera from '/js/components/html_components/menu_camera/MenuCamera';
import MenuCameraWebRtc from '/js/components/html_components/menu_camera/MenuCameraWebRtc';
import RoleManager from './RoleManager';
import PhysicalCameraManager from './PhysicalCameraManager';

/**
 * @class MenuCameraManager
 * @description Handles the behaviour of the cameras that appear on the menu
 * @function add_camera() - Adds a camera object to the menu_camera array this function is called by MenuView.js
 */

// This class manages the cameras of the menu view
class MenuCameraManager
{
  constructor()
  {
    this.menu_cameras = [];
    this.menu_camera = undefined;
    this.menu_cameras_container = undefined;
    this.menu_webrtc_cameras_container = undefined;
  }

  start()
  {
    this.menu_cameras_container = $('.menu__cameras');
    this.menu_camera = $('.menu__cameras-camera');

    // this.menu_webrtc_cameras_container = $('.menu__webrtc-cameras');
    this.menu_webrtc_cameras_container = $('.menu__webrtc');
    this.menu_webrtc_camera = $('.menu__webrtc-camera');
  }

  get_by_name(name)
  {
    for (let i = 0; i < this.menu_cameras.length; i++)
    {
      if (this.menu_cameras[i].physical_camera.name === name)
      {
        return this.menu_cameras[i];
      }
    }

    return undefined;
  }

  add_camera(physical_camera, video_class_name)
  {
    // console.log(`[MenuCameraManager:add_camera]`);
    // console.dir(physical_camera);
    let camera_user_groups_string = '';
    let camera_user_groups = [];
    let should_create = false;

    if (physical_camera)
    {
      camera_user_groups_string = physical_camera.user_groups ? physical_camera.user_groups.replace(/\s/g, '') : '';
    }
    else
    {
      // If no phsyical camera, assume this is a bodyworn camera
      camera_user_groups_string = RoleManager.roles.CUST_BW_USER;
    }

    camera_user_groups = camera_user_groups_string ? camera_user_groups_string.split(',') : [];

    for (let i = 0; i < camera_user_groups.length; i++)
    {
      if (RoleManager.logged_user.groups.includes(camera_user_groups[i]))
      {
        should_create = true;
      }
    }

    // console.log(`[MenuCameraManager:add_camera] should_create check? ${should_create}`);
    if (should_create)
    {
      // console.log(`[MenuCameraManager:add_camera] should_create check? yes`);
      // console.dir(physical_camera);
      let menu_camera;
      // TODO: Temporary look at orientation as bodyworn is missing on a test camera
      if (physical_camera.stream.stream || physical_camera.bodyworn === 'yes' || physical_camera.orientation === 'mobile')
      {
        console.log('[MenuCameraManager:add_camera] webrtc camera');
        // TODO: Change to use menu_webrtc_camera
        // menu_camera = new MenuCameraWebRtc(physical_camera, this.menu_webrtc_camera.clone());
        menu_camera = new MenuCameraWebRtc(physical_camera, this.menu_camera.clone());
        // menu_camera.append_to(this.menu_webrtc_cameras_container);
        menu_camera.append_to(this.menu_cameras_container);
      }
      else
      {
        // console.log(`[MenuCameraManager:add_camera] normal camera`);
        menu_camera = new MenuCamera(physical_camera, this.menu_camera.clone());
        menu_camera.append_to(this.menu_cameras_container);
      }
      this.menu_cameras.push(menu_camera);
    }

    return this.menu_cameras[this.menu_cameras.length - 1];
  }

  // remove_camera(name)
  // {
  //   console.log('[MenuCameraManager:remove_camera]');
  //   // let menu_camera = document.querySelector(`div[class="menu__cameras-camera"][data-name="${name}"]`).remove();
  //   // let menu_camera = document.querySelector(`div[class="menu__cameras-camera"][data-name="${name}"]`);
  //   let menu_camera_el = $(`.menu__cameras-camera[data-name='${name}']`);

  //   // let menu_camera = $('.menu__cameras-camera');
  //   console.dir(menu_camera_el);
  //   menu_camera_el.remove();

  //   let menu_camera = this.get_by_name(name);
  //   if (menu_camera)
  //   {
  //     ArrayUtilities.remove_elem(this.menu_cameras, menu_camera);
  //   }
  //   console.log(PhysicalCameraManager.selected_camera);
  //   if (PhysicalCameraManager.selected_camera &&
  //       PhysicalCameraManager.selected_camera.name === name)
  //   {
  //     console.log('unset activ camera');
  //     PhysicalCameraManager.unset_active_camera();
  //   }
  // }

  play_all_cameras()
  {
    for (let i = 0; i < this.menu_cameras.length; i++)
    {
      this.menu_cameras[i].play();
    }
  }

  pause_all_cameras()
  {
    for (let i = 0; i < this.menu_cameras.length; i++)
    {
      // TODO: This is a hack to prevent the menu camera webRTC stream pausing. Playing the main physicalCamera should start it again
      // console.dir(PhysicalCameraManager.selected_camera);
      if (this.menu_cameras[i].physical_camera.cam_type !== 'phone' && PhysicalCameraManager.selected_camera !== this.menu_cameras[i].physical_camera)
      {
        // console.log(`[MenuCameraManager:pause_all_cameras] Pausing camera = name: ${this.menu_cameras[i].physical_camera.name}`);
        this.menu_cameras[i].pause();
      }
      else
      {
        // console.log(`[MenuCameraManager:pause_all_cameras] Not pausing selected camera = name: ${PhysicalCameraManager.selected_camera.name}`);
        this.menu_cameras[i].play();
      }
    }
  }

  stop_all_cameras()
  {
    // console.log(`[MenuCameraManager:stop_all_cameras]`);
    for (let i = 0; i < this.menu_cameras.length; i++)
    {
      this.menu_cameras[i].stop();
    }
  }

  clear()
  {
    this.menu_cameras = [];
    this.menu_cameras_container.empty();
  }

  camera_updated(camera_name)
  {
    for (let i = 0; i < this.menu_cameras.length; i++)
    {
      if (this.menu_cameras[i].physical_camera.name === camera_name)
      {
        this.menu_cameras[i].config_updated();
      }
    }
  }

  update()
  {
    for (let i = 0; i < this.menu_cameras.length; i++)
    {
      this.menu_cameras[i].update();
    }
  }
}

export default new MenuCameraManager();
