import CesiumHandler from './CesiumHandler';
import MapMarker from './MapMarker';
/**
 * @class  MapTargetMarker
 * @description - When a use right clicks on the map the lat lng and Rng appear to the map
 * The postion of this marker is setted by update_params() on each physicalCamera sub class e.x PhysicalCameraFisheye.
 * @func set_name - Called when user right click on the screen to update the lng lat values.
 */
// This class is a wrapper for a cesium marker target
export default class MapTargetMarker extends MapMarker
{
  constructor(text, lon, lat, alt, map_zoom, tgt_distance)
  {
    super(text, lon, lat, alt, map_zoom);

    this.tgt_distance = tgt_distance;
  }

  create_marker()
  {

    let payload = CesiumHandler.add_marker(
      {
        target_text: this.__create_label(),
        eye_offset: 0.0,
        image_url: 'images/map/target.svg',
        lon: this.lon,
        lat: this.lat,
        alt: this.alt,
        show: false
      }
    );


    this.cesium_marker = payload.marker;
    this.cesium_marker_sphere = payload.marker_sphere;
  }

  show()
  {
    if (this.__is_valid_marker_position(this.lon, this.lat, this.alt))
    {
      this.cesium_marker.show = true;
   	}
  }

  hide()
  {
    this.__hide(this.cesium_marker);
  }

  set_name(name)
  {
    this.text = name;
    this.cesium_marker.label.text = this.__create_label();
    
    this.set_label_color();

  }

  //set the color of the text in Rng,lat,lon text (appears on right click)
  set_label_color()
  {
    this.cesium_marker.label.fillColor = CesiumHandler.label_color;
    this.cesium_marker.billboard.color = CesiumHandler.label_color;

  }

  __create_label()
  {

    let label = this.text ? `Name: ${this.text}\n` : '';
    label += `Lat: ${this.lat}\n` +
             `Lon: ${this.lon}\n`;

    label += this.tgt_distance ? `Rng: ${this.tgt_distance}` : '';

    return label;
  }
}
