import { Configuration } from 'ohzi-core';

import ApplicationView from './common/ApplicationView';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import MenuCameraManager from '../MenuCameraManager';
import LiveMode from './menu_view_mode/LiveMode';
import RecordingsMode from './menu_view_mode/RecordingsMode';
import ArchiveMode from './menu_view_mode/ArchiveMode';
import RoleManager from '../RoleManager';
import MenuSearch from './MenuSearch';
import TaglockMarkerManager from '/js/components/TaglockMarkerManager';
import PlayerMarkerManager from '../PlayerMarkerManager';

/**
 *  @class MenuView
 *  @description This class is responsible for the display of the main menu of the application
 *  @function fill_menu() - @description populating the menu view with camera objects
 *  @function add_camera() - @description add camera to the menu view
 *  @function selectTab() -  @description Change active tab on click.Called from api.js
 *  @function select_camera() - @description Sets selected camera on click.Called by MenuCamera
 *  @function select_camera_in_menu() - @description When a user selects a camera,removes or add several html elemnet from the ui
 */

// This represents the menu panel that contains the live and recordings section.
export default class MenuView extends ApplicationView
{
  constructor(app)
  {
    super('menu', $('.menu'));

    this.app = app;
    this.cameras_index = 0;
    this.is_opened = true;
    this.nested_click = false;

    this.cameras_per_page = 6;
    this.last_camera_index = [0];
    this.menu_index = 0;

    this.search = new MenuSearch(app, this);

    this.tabs = {
      LIVE: new LiveMode(),
      RECORDINGS: new RecordingsMode(),
      ARCHIVE: new ArchiveMode()
    };
  }

  test(value)
  {
    // To run in chrome devtools
    // app.menu_view.test()
    console.log('[MenuView:test]');

    console.log('[MenuView:test] set test phone camera to test_cam');

    var test_cam = PhysicalCameraManager.get_by_name('Test Phone');
    console.dir(test_cam);

    let cameras_index = PhysicalCameraManager.cameras.length;

    console.log(`[MenuView:test] Physical Cameras: ${cameras_index}`);
    console.dir(PhysicalCameraManager.cameras);
    for (let i = 0; i < cameras_index; i++)
    {
      let physical_camera = PhysicalCameraManager.cameras[i + this.cameras_index];
      console.log(physical_camera.name);
      console.dir(physical_camera);
    }

    if (value == 1)
    {
      console.log('[MenuView:test] Show cesium_marker_plane');
      // PhysicalCameraManager.selected_camera_tandem.map_marker.cesium_marker_plane.show = true;

      let videoElement = PhysicalCameraManager.selected_camera_tandem.plane_stream.html_video.container;
      console.dir(videoElement);
      let video_width = videoElement.videoWidth;
      let video_height = videoElement.videoHeight;
      // this.selected_camera_tandem.map_marker.set_image_url(videoElement);
      PhysicalCameraManager.selected_camera_tandem.map_marker.set_video_resolution(video_height, video_width);
    }

    return;

    console.log('[MenuView:test] Updating token');
    RoleManager.__on_token_expired();

    // let cameras_index = PhysicalCameraManager.cameras.length;

    console.log(`[MenuView:test] Physical Cameras: ${cameras_index}`);
    console.dir(PhysicalCameraManager.cameras);
    for (let i = 0; i < cameras_index; i++)
    {
      let physical_camera = PhysicalCameraManager.cameras[i + this.cameras_index];
      console.dir(physical_camera);
    }

    cameras_index = MenuCameraManager.menu_cameras.length;
    console.dir(MenuCameraManager.menu_cameras);

    console.log(`[MenuView:test] Menu Cameras: ${cameras_index}`);
    for (let i = 0; i < cameras_index; i++)
    {
      let menu_camera = MenuCameraManager.menu_cameras[i + this.cameras_index];
      console.dir(menu_camera);
    }

    return;

    let name = 's10test';
    // let name = "YorkPTZ";

    let height = 0;
    let width = 0;

    var m_cam = MenuCameraManager.get_by_name(name);
    console.dir(m_cam);

    this.cam = m_cam;

    if (!m_cam.stream.html_video.container.srcObject)
    {
      console.log('[MenuView:test] srcObject is null');
    }

    console.log(`track[0].enabled: ${m_cam.stream.html_video.container.srcObject.getTracks()[0].enabled}, track[1].enabled: ${m_cam.stream.html_video.container.srcObject.getTracks()[1].enabled}`);
    if (value)
    {
      // m_cam.stream.html_video.container.srcObject.getTracks()[1].enabled = true;
      m_cam.stream.html_video.container.srcObject.getVideoTracks()[0].enabled = true;
      // height = m_cam.stream.html_video.container.srcObject.getVideoTracks()[0].getSettings().height;
      // width = m_cam.stream.html_video.container.srcObject.getVideoTracks()[0].getSettings().width;
    }
    else
    {
      // m_cam.stream.html_video.container.srcObject.getTracks()[1].enabled = false;
      m_cam.stream.html_video.container.srcObject.getVideoTracks()[0].enabled = false;
      height = m_cam.stream.html_video.container.srcObject.getVideoTracks()[0].getSettings().height;
      width = m_cam.stream.html_video.container.srcObject.getVideoTracks()[0].getSettings().width;
    }
    let tracks = m_cam.stream.html_video.container.srcObject.getTracks();
    let tracks2 = m_cam.stream.html_video.container.srcObject.getTracks()[0];
    let tracks3 = m_cam.stream.html_video.container.srcObject.getTracks()[1];
    let vidTracks = m_cam.stream.html_video.container.srcObject.getVideoTracks();
    console.dir(tracks);
    console.dir(tracks2);
    console.dir(tracks3);
    console.dir(vidTracks);

    console.log(`[MenuView:test] WebRTC Resolution ${height}x${width}`);

    console.log('[MenuView:test] end');
  }

  start()
  {
    MenuCameraManager.start();

    this.close_button = $('.menu__buttons-close');
    this.open_button = $('.menu__buttons-open');
    this.options_button = $('.menu__body-options');
    this.menu_cameras_container = $('.menu__cameras');
    this.tabs_tab = $('.menu__tabs-tab');
    this.tabs_tab_live = $('.menu__tabs-tab--live');
    this.tabs_tab_recordings = $('.menu__tabs-tab--recordings');
    this.tabs_tab_archive = $('.menu__tabs-tab--archive');
    this.menu_handle = $('.menu__handle');
    this.pagination_dots = $('.menu__pagination-dots');
    this.pagination_dot_template = $('.menu__pagination-dot').clone();

    if (!Configuration.is_mobile)
    {
      this.options_button.removeClass('hidden');
      this.container.css('width', localStorage.getItem('menu_width'));
    }

    this.search.start();

    this.current_tab = this.tabs.LIVE;

    this.menu_camera = $('.menu__cameras-camera');

    this.fill_menu(0);

    this.menu_handle.on('mousedown touchstart', this.__on_mouse_down.bind(this));
  }

  // Closes the menu panel
  close()
  {
    if (!this.app.menu_options_view.keep_menu_visible)
    {
      this.is_opened = false;
      this.container.addClass('closed');
      MenuCameraManager.pause_all_cameras();
    }
  }

  // Opens the menu panel
  open()
  {
    this.is_opened = true;
    this.container.removeClass('closed');

    MenuCameraManager.play_all_cameras();

    if (Configuration.is_mobile)
    {
      this.app.player_container.hide();
    }
  }

  // The tab refers to live, reordings or archive
  select_tab(tab, tab_el)
  {
    this.menu_cameras_container.removeClass('hidden');
    this.tabs_tab.removeClass('selected');

    this.app.calendar_view.hide();
    this.app.recordings_list_view.hide();

    $('.menu__selected-camera').addClass('hidden');
    $('.menu__pagination').removeClass('hidden');
    $(tab_el).addClass('selected');

    this.current_tab = tab;

    this.app.player_container.hide();

    tab.on_tab_selected(this.app.menu_view);
  }

  open_settings()
  {
    this.app.camera_settings_view.show();
    this.hide();
  }

  open_camera_calibration()
  {  
    this.app.camera_calibration_view.show();
    this.hide();
  }

  logout()
  {
    RoleManager.logout();
  }

  // This is called when a camera is selected in one of the tabs.
  // It will set up the cesium location and player video.
  select_camera(camera_el, e)
  {
    if (!this.nested_click)
    {
      let camera_name = $(camera_el).data('name');
      let camera = PhysicalCameraManager.get_by_name(camera_name);

      if (camera) // && camera.is_online
      {
        this.select_physical_camera(camera);
      }
    }
    else
    {
      this.nested_click = false;
    }
  }

  // This is called when a camera tandem switch is clicked
  // This will setup the camera for display on the cesium map only
  toggle_tandem_mode(camera_el, e)
  {
    this.nested_click = true;

    // console.log(`[MenuView:toggle_tandem_mode] Left clicked`);
    // console.dir(e);
    if (Configuration.is_ios || Configuration.is_mobile)
    {
      return;
    }
    let camera_name = $(camera_el).data('name');
    let camera = PhysicalCameraManager.get_by_name(camera_name);

    // console.dir(camera_el);
    // console.dir(camera);
    if (camera) // && camera.is_online
    {
      let last_state = camera_el.classList.contains('active');

      // Turn off all tandem switches
      var tandem_switches = document.querySelectorAll('.menu__cameras-camera-tandem');
      [].forEach.call(tandem_switches, function(item)
      {
        item.classList.remove('active');
      });

      this.__toggle_switch_elem(camera_el, !last_state);

      if (last_state)
      {
        PhysicalCameraManager.unset_active_tandem_camera(camera);
      }
      else
      {
        PhysicalCameraManager.set_active_tandem_camera(camera);
      }

      this.current_tab.on_tandem_selected(this);
    }
  }

  // This is called when a camera map view switch is clicked
  // This will toggle the video plane on the map for mobile (webRTC) devices
  toggle_map_view_mode(camera_el, e)
  {
    this.nested_click = true;

    console.log('[MenuView:toggle_map_view_mode]');

    let camera_name = $(camera_el).data('name');
    let camera = PhysicalCameraManager.get_by_name(camera_name);

    if (camera)
    {
      let last_state = camera_el.classList.contains('active');

      this.__toggle_switch_elem(camera_el, !last_state);

      if (last_state)
      {
        console.log('[MenuView:toggle_map_view_mode] hide marker');
        // Hide the map marker plane only
        camera.map_marker.hide(true);
      }
      else
      {
        console.log('[MenuView:toggle_map_view_mode] show marker');
        // Hide the map marker plane only
        camera.show_map_marker(false);
      }
      camera.map_marker.update_arrow_text_position();
    }
  }

  show_calendar_view()
  {
    this.menu_cameras_container.addClass('hidden');

    this.app.calendar_view.show();
    $('.menu__selected-camera').removeClass('hidden');

    $('.menu__selected-camera').text(`Selected camera: ${PhysicalCameraManager.selected_camera.name}`);
    $('.menu__pagination').addClass('hidden');
  }

  show_recordings_list_view()
  {
    this.app.recordings_list_view.show();
  }

  // This will set up the camera video elements being displayed on the menu
  fill_menu(offset, filter = '')
  {
    this.menu_index += offset;

    let max_pages = Math.ceil(PhysicalCameraManager.cameras.length / this.cameras_per_page);
    this.menu_index = THREE.Math.clamp(this.menu_index, 0, max_pages - 1);

    if (this.menu_index > 0)
    {
      this.cameras_index = this.last_camera_index[this.menu_index - 1] + 1;
    }
    else
    {
      this.cameras_index = 0;
    }
    // console.log(`[MenuView:fill_menu] max pages: ${max_pages}, menu_index: ${this.menu_index}, offset: ${offset}, cameras_index: ${this.cameras_index}`);

    if (this.cameras_index <= PhysicalCameraManager.cameras.length - 1)
    {
      MenuCameraManager.clear();
      this.pagination_dots.empty();

      this.cameras_index = THREE.Math.clamp(this.cameras_index, 0, PhysicalCameraManager.cameras.length);

      let num_cameras = 0;
      let i = 0;

      // for (let i = 0; i < PhysicalCameraManager.cameras.length; i++) {
      //   const camera = PhysicalCameraManager.cameras[i];

      // }

      let sorted_cameras = this.get_sorted_cameras();

      while ((i + this.cameras_index) < sorted_cameras.length)
      {
        let physical_camera = sorted_cameras[i + this.cameras_index];

        // console.log(`[MenuView:fill_menu] Checking if we should add camera[${i}]: ${physical_camera.name}`);

        // if (physical_camera && (physical_camera.bodyworn !== 'yes' || this.app.menu_view.current_tab.name === 'archive'))
        if (physical_camera)
        {
          console.log({physical_camera})
          // console.log(`[MenuView:fill_menu] Adding Menu camera: ${physical_camera.name}`);

          if (this.current_tab.is_valid_camera(physical_camera) && physical_camera.name.toLowerCase().includes(filter.toLowerCase()))
          {
            this.add_camera(physical_camera);
            num_cameras++;
            this.last_camera_index[this.menu_index] = i + this.cameras_index;
          }
        }

        if (num_cameras >= this.cameras_per_page)
        {
          break;
        }
        i++;
      }

      this.add_pagination_dots();
    }
  }

  // TODO: Move to PhysicalCameraManager
  get_sorted_cameras()
  {
    return PhysicalCameraManager.cameras.sort(function(a, b)
    {
      return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' });
    });
  }

  add_pagination_dots()
  {
    let dots_amount = Math.ceil(PhysicalCameraManager.cameras.length / this.cameras_per_page);

    for (let i = 0; i < dots_amount; i++)
    {
      let active_dot_index = Math.floor(this.cameras_index / this.cameras_per_page);
      let new_dot = this.pagination_dot_template.clone();

      if (active_dot_index === i)
      {
        new_dot.addClass('active');
      }

      this.pagination_dots.append(new_dot);
    }
  }

  add_camera(physical_camera)
  {
    // console.log(`[MenuView:add_camera] Name: ${physical_camera.name}`);
    // console.dir(physical_camera);
    let menu_camera = MenuCameraManager.add_camera(physical_camera);

    // console.dir(menu_camera);
    if (menu_camera)
    {
      /* if (physical_camera.bodyworn === `yes` &&)
      {
        //console.log(`[MenuView:add_camera] Hiding bodyworn menu camera`);
        menu_camera.hide();
      } */

      if (this.app.menu_options_view.show_thumbnail_video)
      {
        menu_camera.start_streaming();
      }

      if (this.app.menu_options_view.show_thumbnail_image)
      {
        menu_camera.start_streaming(true);
      }
      if (!physical_camera || (physical_camera && physical_camera.lens_type === 'flat'))
      {
        this.hide_camera_fisheye_frame(physical_camera.name);
      }
    }
  }

  select_physical_camera(camera)
  {
    this.select_camera_in_menu(camera.name);
    TaglockMarkerManager.hide_select_taglock();

    if (this.current_tab == this.tabs.LIVE)
    {
      PhysicalCameraManager.set_active_camera(camera);
    }
    else
    {
      PhysicalCameraManager.set_active_camera(camera, true);  // Don't fly to camera location
    }

    this.app.player_container.show_loading();

    let menu_camera = MenuCameraManager.get_by_name(camera.name);
    if (camera.lens_type === 'fisheye')
    {
      camera.map_marker.set_image_url(menu_camera.stream.get_screenshot_url());
      // this.app.player_settings.hide_lockview_all_axis_target();
    }

    this.app.camera_settings_view.fill_hotspot_select();
    PlayerMarkerManager.reset_temp_camera();

    this.current_tab.on_camera_selected(this);
  }

  select_camera_in_menu(camera_name)
  {
    let camera_el = $(`.menu__cameras-camera[data-name='${camera_name}']`);

    $('.menu__cameras-camera__container').removeClass('selected');
    $('.menu__cameras-camera-image-img').removeClass('selected');
    $(camera_el).find('.menu__cameras-camera__container').addClass('selected');
    $(camera_el).find('.menu__cameras-camera-image-img').addClass('selected');

    // Set map_view button on
    $(camera_el).find('.menu__cameras-camera-map_view').addClass('active');
  }

  hide_camera_fisheye_frame(camera_name)
  {
    let camera_el = $(`.menu__cameras-camera[data-name='${camera_name}']`);

    $(camera_el).find('.menu__cameras-camera-image-img').addClass('invisible');
  }

  update()
  {
    // ios let height = $('.menu__cameras-camera-image-img').width();
    // $('.menu__cameras-camera-image').css('min-height', height);

    let menu_cameras = document.querySelectorAll('.menu__cameras-camera-image');

    for (let i = 0; i < menu_cameras.length; i++)
    {
      const height = menu_cameras[i].querySelector('.menu__cameras-camera-image-img').getBoundingClientRect().height;
      menu_cameras[i].style.height = `${height - 4}px`;
    }
  }

  hide_options()
  {
    this.options_button.addClass('hidden');
  }

  hide_live_tab()
  {
    this.tabs_tab_live.addClass('hidden');
  }

  hide_recordings_tab()
  {
    this.tabs_tab_recordings.addClass('hidden');
  }

  hide_archive_tab()
  {
    this.tabs_tab_archive.addClass('hidden');
  }

  __on_mouse_down(e)
  {
    let origin = this.menu_handle.position().left;
    let touch = e.originalEvent.touches;
    let start = touch ? touch[0].pageX : e.pageX;

    $(window).on('mousemove touchmove', this.__on_mouse_move.bind(this, origin, touch, start));
    $(window).one('mouseup touchend', this.__on_mouse_up.bind(this));

    e.preventDefault();
  }

  __on_mouse_move(origin, touch, start, e)
  {
    let contact = e.originalEvent.touches;
    let end = touch ? contact[0].pageX : e.pageX;
    let difference = end - start;

    let newleft = Math.max(0, origin + difference);

    let newwidth = newleft + 35;

    this.menu_handle.css('left', newleft);
    this.container.css('width', newwidth);
  }

  __on_mouse_up(e)
  {
    e.preventDefault();
    $(window).off('mousemove touchmove');
    localStorage.setItem('menu_width', this.container.width());
  }

  __toggle_switch_elem(elem, should_activate)
  {
    if (should_activate)
    {
      $(elem).addClass('active');
    }
    else
    {
      $(elem).removeClass('active');
    }
  }
}
