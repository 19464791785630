import PlayerViewMode from './PlayerViewMode';
import RecordingMode from './RecordingMode';
import { Input } from 'ohzi-core';
import TimeUtilities from '../../TimeUtilities';

// This represents the Detailed Recording mode of the PlayerView
export default class DetailedRecordingMode extends PlayerViewMode
{
  on_enter(player_view)
  {
    player_view.live_label.addClass('hidden');

    player_view.playback_bar.show();

    player_view.playback_bar.show_range_labels();
    // player_view.playback_bar.show_time_label();
    player_view.playback_bar.detailed_playback_bar.show(player_view.playback_bar);

    this.__set_range(player_view);

    player_view.pause();
  }

  update(player_view)
  {
    let mouse_ms = player_view.playback_bar.get_mouse_ms();

    player_view.playback_bar.set_cursor_ms(mouse_ms);
    player_view.playback_bar.set_progress_ms(mouse_ms);

    player_view.playback_bar.set_time_label_ms(player_view.playback_bar.get_mouse_ms());

    this.__check_for_recording_mode(player_view);
  }

  __check_for_recording_mode(player_view)
  {
    if (Input.left_mouse_button_released)
    {
      player_view.play_recording_at_ms(player_view.playback_bar.get_mouse_ms());
      //player_view.set_mode(new RecordingMode());
      if (player_view.app.menu_view.current_tab.name == 'archive')
      {
        // Set the player view into archive mode
        player_view.set_archive_mode();
      }
      else
      {
        // Set the player view into recording mode
        player_view.set_recordings_mode();
      }
    }
  }

  __set_range(player_view)
  {
    let mouse_ms = player_view.playback_bar.get_mouse_ms();
    let normalized_mouse_ms = mouse_ms / TimeUtilities.full_day_ms;

    let start_time = mouse_ms - (normalized_mouse_ms * 10 * 60 * 1000);
    let end_time = mouse_ms + ((1 - normalized_mouse_ms) * 10 * 60 * 1000);

    // Set Playback bar range to 1 hour
    player_view.playback_bar.set_range(start_time, end_time);
  }
}
