import ApplicationView from './common/ApplicationView';
import MenuCameraManager from '/js/components/MenuCameraManager';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import CesiumHandler from '/js/components/CesiumHandler';

/**
 * @class MenuOptionsView
 * @description This class is responsible for the pop up menu that appears when we are clicking the options button.
 * menu_options.pug is the template that is connected.
 */

// This represents the menu panel that contains the live and recordings section.
export default class MenuOptionsView extends ApplicationView
{
  constructor(app)
  {
    super('menu__options', $('.menu__options'));

    this.app = app;

    this.is_opened = false;

    this.thumbnail_types = {
      VIDEO: 'video',
      IMAGE: 'image',
      OFF: 'off'
    };

    this.active_tgt_types = {
      JSON: 'json',
      NMEA: 'nmea',
      OFF: 'off'
    };

    this.keep_menu_visible = (localStorage.getItem('keep_menu_visible') === 'true') || false;

    let internal_show_diagnostic_messages = localStorage.getItem('show_diagnostic_messages');
    this.show_diagnostic_messages = internal_show_diagnostic_messages === null ? true : (internal_show_diagnostic_messages === 'true');

    let internal_show_thumbnail_video = localStorage.getItem('show_thumbnail_video');
    this.show_thumbnail_video = internal_show_thumbnail_video === null ? true : (internal_show_thumbnail_video === 'true');

    this.show_thumbnail_image = (localStorage.getItem('show_thumbnail_image') === 'true') || false;

    this.active_tgt_json = false;
    this.active_tgt_nmea = false;

    let internal_show_cesium_buildings = localStorage.getItem('show_cesium_buildings');
    this.show_cesium_buildings = internal_show_cesium_buildings === null ? false : (internal_show_cesium_buildings === 'true');

    this.temp_cnt = 0;
  }

  start()
  {
    this.menu_options_container = $('.menu__options-container');
    this.menu_options_button = $('.menu__body-options');

    this.menu_options_item_menu_visible = document.querySelector('.menu__options-item--menu-visible');
    this.menu_options_item_diagnostic_messages = document.querySelector('.menu__options-item--diagnostic-messages');
    this.menu_options_item_cesium_buildings = document.querySelector('.menu__options-item--cesium-buildings');
    this.menu_options_item_thumbnail_video = document.querySelector('.menu__options-item--thumbnail-video');
    this.menu_options_item_thumbnail_image = document.querySelector('.menu__options-item--thumbnail-image');
    this.menu_options_item_thumbnail_off = document.querySelector('.menu__options-item--thumbnail-off');
    this.menu_options_item_active_tgt_json = document.querySelector('.menu__options-item--active-tgt-json');
    // this.menu_options_item_active_tgt_nmea = document.querySelector('.menu__options-item--active-tgt-nmea');
    this.menu_options_item_active_tgt_off = document.querySelector('.menu__options-item--active-tgt-off');

    this.__toggle_switch_elem(this.menu_options_item_menu_visible, this.keep_menu_visible);
    this.__toggle_switch_elem(this.menu_options_item_diagnostic_messages, this.show_diagnostic_messages);
    this.__toggle_switch_elem(this.menu_options_item_cesium_buildings, this.show_cesium_buildings);
    this.__toggle_switch_elem(this.menu_options_item_thumbnail_video, this.show_thumbnail_video);
    this.__toggle_switch_elem(this.menu_options_item_thumbnail_image, this.show_thumbnail_image);
    this.__toggle_switch_elem(this.menu_options_item_thumbnail_off, (!this.show_thumbnail_image && !this.show_thumbnail_video));

    this.__toggle_switch_elem(this.menu_options_item_active_tgt_json, this.active_tgt_json);
    // this.__toggle_switch_elem(this.menu_options_item_active_tgt_nmea, this.active_tgt_nmea);
    this.__toggle_switch_elem(this.menu_options_item_active_tgt_off, (!this.active_tgt_json && !this.active_tgt_nmea));
    
    this.click_event = window.addEventListener('mousedown', this.__on_container_click.bind(this));
    this.menu_options_container[0].addEventListener('mousedown', this.__on_menu_options_container_click.bind(this));

    if (this.show_cesium_buildings)
    {
      CesiumHandler.enable_osm_buildings();
    }
  }

  show()
  {
    super.show();

    this.menu_options_button.addClass('active');
    this.is_opened = true;
  }

  hide()
  {
    super.hide();

    this.menu_options_button.removeClass('active');
    this.is_opened = false;
  }

  // Called by the UI (menu_options.pug) when the user clicks on the menu visible toggle
  toggle_menu_visible()
  {
    this.keep_menu_visible = !this.keep_menu_visible;

    this.__toggle_switch_elem(this.menu_options_item_menu_visible, this.keep_menu_visible);

    localStorage.setItem('keep_menu_visible', this.keep_menu_visible);
  }

  // Called by the UI (menu_options.pug) when the user clicks on the diagnostic messages toggle
  toggle_diagnostic_messages()
  {
    this.show_diagnostic_messages = !this.show_diagnostic_messages;

    this.__toggle_switch_elem(this.menu_options_item_diagnostic_messages, this.show_diagnostic_messages);

    localStorage.setItem('show_diagnostic_messages', this.show_diagnostic_messages);
  }

  // Called by the UI (menu_options.pug) when the user clicks on the Active TGT toggle
  toggle_active_tgt(type)
  {
    this.temp_cnt += 1;
    console.log(`[toggle_active_tgt] [${this.temp_cnt}] type: ${type}`);
    switch (type)
    {
    case this.active_tgt_types.JSON:
      // this.app.target_serial_port_bridge.disconnect();
      /* this.app.target_telemetry_controller.connect();
      this.active_tgt_json = true;
      this.active_tgt_nmea = false;
      */
      if (this.app.target_telemetry_controller.connect())
      {
        console.log(`[toggle_active_tgt] [${this.temp_cnt}] JSON connect success`);
        this.active_tgt_json = true;
        this.active_tgt_nmea = false;
      }
      else
      {
        console.log(`[toggle_active_tgt] [${this.temp_cnt}] JSON connect failed`);
        this.active_tgt_json = false;
        this.active_tgt_nmea = false;
      }
      break;
    case this.active_tgt_types.NMEA:
      this.app.target_telemetry_controller.disconnect();
      // this.app.target_serial_port_bridge.connect();
      this.active_tgt_json = false;
      this.active_tgt_nmea = true;
      break;
    case this.active_tgt_types.OFF:
      this.app.target_telemetry_controller.disconnect();
      // this.app.target_serial_port_bridge.disconnect();
      this.active_tgt_json = false;
      this.active_tgt_nmea = false;
      break;
    }

    console.log(`[toggle_active_tgt] [${this.temp_cnt}] Toggling Elements: active_tgt_json: ${this.active_tgt_json}, active_tgt_nmea: ${this.active_tgt_nmea}`);

    this.__toggle_switch_elem(this.menu_options_item_active_tgt_json, this.active_tgt_json);
    // this.__toggle_switch_elem(this.menu_options_item_active_tgt_nmea, this.active_tgt_nmea);
    this.__toggle_switch_elem(this.menu_options_item_active_tgt_off, (!this.active_tgt_json && !this.active_tgt_nmea));
  }

  // Called by the UI (menu_options.pug) when the user clicks on the thumbnail video toggle
  toggle_thumbnail(type)
  {
    switch (type)
    {
    case this.thumbnail_types.VIDEO:
      this.show_thumbnail_image = false;
      this.show_thumbnail_video = true;
      break;
    case this.thumbnail_types.IMAGE:
      this.show_thumbnail_image = true;
      this.show_thumbnail_video = false;
      break;
    case this.thumbnail_types.OFF:
      this.show_thumbnail_image = false;
      this.show_thumbnail_video = false;
      break;
    }

    this.__toggle_switch_elem(this.menu_options_item_thumbnail_video, this.show_thumbnail_video);
    this.__toggle_switch_elem(this.menu_options_item_thumbnail_image, this.show_thumbnail_image);
    this.__toggle_switch_elem(this.menu_options_item_thumbnail_off, (!this.show_thumbnail_image && !this.show_thumbnail_video));

    MenuCameraManager.stop_all_cameras();
    this.app.menu_view.fill_menu(0);

    if (PhysicalCameraManager.selected_camera)
    {
      this.app.menu_view.select_camera_in_menu(PhysicalCameraManager.selected_camera.name);
    }

    localStorage.setItem('show_thumbnail_image', this.show_thumbnail_image);
    localStorage.setItem('show_thumbnail_video', this.show_thumbnail_video);
  }

  // Called by the UI (menu_options.pug) when the user clicks on the cesium OSM buildings toggle
  toggle_cesium_buildings()
  {
    this.show_cesium_buildings = !this.show_cesium_buildings;

    this.__toggle_switch_elem(this.menu_options_item_cesium_buildings, this.show_cesium_buildings);

    if (this.show_cesium_buildings)
    {
      CesiumHandler.enable_osm_buildings();
    }
    else
    {
      CesiumHandler.disable_osm_buildings();
    }

    localStorage.setItem('show_cesium_buildings', this.show_cesium_buildings);
  }

  __toggle_switch_elem(elem, should_activate)
  {
    if (should_activate)
    {
      $(elem).addClass('active');
    }
    else
    {
      $(elem).removeClass('active');
    }
  }

  __on_container_click()
  {
    if (this.is_opened)
    {
      this.hide();
    }
  }

  __on_menu_options_container_click(event)
  {
    event.stopImmediatePropagation();
  }
}
