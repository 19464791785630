import { ObjectUtilities } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';

import FisheyeSphere from '/js/components/FisheyeSphere';
import MapMarker from '/js/components/MapMarker';
import MapTargetMarker from '/js/components/MapTargetMarker';
import PlayerSettings from '/js/components/PlayerSettings';

import RoleManager from './RoleManager';

// This represents the camera containing the config variables used for the application
/**
 * @class PhysicalCamera
 * @description This class creates an instance of a camera object.
 * @function set_state_from_json() - Set the state of the camera, this i called from CameraSettingView when a user apply new camera settings
 * throught the PhysicalCameraManager update_camera() function.
 * @function get_recordings_from_camera - Http request to get all the recordings from the selected camera.
 * Called by calendar view.
 */
export default class PhysicalCamera
{
  constructor(params, stream, texture)
  {
    // this.is_online = false;
    this.use_upper_hemisphere = false;
    this.default_map_marker_alt = 50;
    this.map_target_zoom = 1000;

    this.texture  = texture;
    this.stream   = stream;
    this.set_state_from_json(params);

    this.tandem_enabled = false;
    this.fly_on_valid_telemetry = false;
    this.is_telemetry_streaming = false;
    this.last_telemetry_update = 0;

    this.map_marker = undefined; // Defined in child Instances
    this.fisheye_sphere = undefined; // Defined in child Instances
    this.map_target_marker      = new MapTargetMarker('', this.tgt_lon, this.tgt_lat, this.tgt_alt, this.map_zoom);
  }

  update()
  {
    this.map_marker.update();

    this.fisheye_sphere.update();
  }

  is_streaming()
  {
    return this.stream.is_streaming;
  }

  is_live_stream_available()
  {
    return true;
  }

  is_json(str)
  {
    try
    {
      JSON.parse(str);
      return true;
    }
    catch (e)
    {
      return false;
    }
  }

  is_editable()
  {
    return !((this.bodyworn && this.bodyworn.toLowerCase() === 'yes') ||
            (this.cam_type && this.cam_type === 'phone'));
  }

  set_state_from_json(params)
  {
    let keys = Object.keys(params);

    for (let i = 0; i < keys.length; i++)
    {
      let value = params[keys[i]];

      if (value !== undefined && this[keys[i]] !== value)
      {
        if (!PlayerSettings.player_tag_lock && (
          keys[i] === 'tgt_lat' ||
          keys[i] === 'tgt_lon' ||
          keys[i] === 'tgt_alt'/* ||
          keys[i] === "image_pan" ||
          keys[i] === "iamge_tilt" ||
          keys[i] === "icon_pan" ||
          keys[i] === "icon_tilt" */))
        {
          // console.log(`[PhysicalCamera:set_state_from_json] NOT Copying ${keys[i]} = ${value}`);
        }
        else
        {
          // If a target message type don't override time
          if (!(params.type === 'target' && keys[i] === 'time'))
          {
            this[keys[i]] = this.is_json(value) ? JSON.parse(value) : value;
            if (keys[i] === 'name')
            {
              this[keys[i]] = '' + this[keys[i]];
            }
          }
        }
      }
    }
    this.use_upper_hemisphere = this.orientation === 'up';

    // console.log(`[PhysicalCamera:set_state_from_json]`);
    // console.dir(this);
  }

  show_map_target_marker()
  {
    this.map_target_marker.show();
  }

  hide_map_target_marker()
  {
    this.map_target_marker.hide();
  }

  show_map_marker(show_marker_partial)
  {
    // if (show_marker_partial)
    // {
    //   console.log(`[PhysicalCamera:show_map_marker] setting partial: ${show_marker_partial}`);
    //   this.show_marker_partial = show_marker_partial;
    // }

    // console.log(`[PhysicalCamera:show_map_marker] show partial: ${show_marker_partial}`);
    this.map_marker.show(show_marker_partial);
  }

  hide_map_marker()
  {
    this.map_marker.hide();
  }

  fly_to_camera_marker()
  {
    this.update_params();
    if (this.lens_type === 'fisheye')
    {
      this.map_marker.fly_to_new_position(this.map_zoom);
    }
    else
    {
      this.map_marker.fly_to_camera_position(this, this.map_zoom);
    }
  }

  fly_to_target_marker()
  {
    this.map_target_marker.fly_to_new_target_position(this.map_target_zoom);
  }

  set_view_to_plane(lock_all_axis)
  {
    console.log(`[PhysicalCamera:set_view_to_plane] name: ${this.name}, map_zoom: ${this.map_zoom}`);
    console.dir(this);
    // TEMP to test viewing WebrTC archive video on plane
    if (!this.cam_lat)
    {
      this.cam_lat = -36.855349;
      this.cam_lon = 174.816926;
      this.cam_alt = 100.0;

      this.yaw = 90.0;
      this.roll = 0.0;
      this.pitch = 0.0;
      this.update_params();
    }
    this.map_marker.set_view_to_plane(this.map_zoom, lock_all_axis);
  }

  get_recordings_from_camera(callback, callback_scope, resource_url)
  {
    if (!this._id)
    {
      console.warn(`This camera '${this.name}' has no _id so cannot get recordings list`);
      return;
    }

    let app_config = ResourceContainer.get_resource('config');
    let cam_id = this._id;

    let data = {
      // camera_id: this._id
      camera_id: cam_id
    };

    $.ajax({
      type: 'POST',
      url: `${app_config.recordings_api_host}/${resource_url}`,
      headers: RoleManager.get_auth_header(),
      timeout: 10000,
      dataType: 'json',
      data: JSON.stringify(data),
      complete: this.__response_to_json.bind(this, callback, callback_scope)
    });
  }

  play()
  {
    // console.log('[PhysicalCamera:play]');
    this.fisheye_sphere.is_playing = true;
  }

  pause()
  {
    // console.log('[PhysicalCamera:pause]');
    this.fisheye_sphere.is_playing = false;
    // GG I can't see anywhere where the actual RTSP streams are being stopped when we swap cameras
    // this.stream.stop();
  }

  stop()
  {
    // TODO: WHy has this been added? Part of IOS integration

    console.log('[PhysicalCamera:stop]');
    this.fisheye_sphere.is_playing = false;
    // GG I can't see anywhere where the actual RTSP streams are being stopped when we swap cameras
    // this.stream.stop();
  }

  dispose()
  {
    console.log('[PhysicalCamera:dispose]');
    this.map_marker.dispose();
    this.fisheye_sphere.dispose();
  }

  __update_name_in_menu()
  {
    let camera_name = $(`.menu__cameras-camera[data-name='${this.name}']`).find('.menu__cameras-camera-title');
    camera_name.text(this.name);
  }

  __response_to_json(callback, callback_scope, response)
  {
    console.log('[PhysicalCamera:__response_to_json]');
    console.dir(response);

    if (response.status === 500)
    {
      console.warn(`Camera recording list request error: ${response.responseText}`);
      console.dir(response);
      return;
    }

    if (response.status !== 0)
    {
      let parser = new DOMParser();
      let xml = parser.parseFromString(response.responseText, 'text/xml');

      // console.dir(xml);
      // console.dir(ObjectUtilities.xml_to_json(xml));
      // console.dir(ObjectUtilities.xml_to_json(xml).root);
      // console.dir(ObjectUtilities.xml_to_json(xml).root.recordings);

      let bind_callback = callback.bind(callback_scope);
      bind_callback([].concat(ObjectUtilities.xml_to_json(xml).root.recordings.recording), this);
      // this.is_online = true;
    }
  }
}
