import PlayerViewMode from './PlayerViewMode';
import TimeUtilities from '../../TimeUtilities';
import PhysicalCameraManager from '../../PhysicalCameraManager';

// This represents the Live mode of the PlayerView
export default class LiveMode extends PlayerViewMode
{
  on_enter(player_view)
  {
    player_view.playback_bar.set_range(0, TimeUtilities.full_day_ms);

    player_view.live_label.removeClass('hidden');

    player_view.extract_recording_view.hide();
    player_view.extract_recording_view.open_button.removeClass('active');

    player_view.playback_bar.hide();

    player_view.playback_bar.hide_range_labels();
    // player_view.playback_bar.hide_time_label();

    player_view.playback_bar.clean_fragments();
    player_view.playback_bar.add_fragment(undefined, 0, TimeUtilities.full_day_ms);

    player_view.pause();
  }

  update(player_view)
  {
    player_view.app.camera_controller.update();
    player_view.app.camera_controller_tandem.update();

    player_view.playback_bar.set_cursor_ms(TimeUtilities.full_day_ms);
    player_view.playback_bar.set_progress_ms(TimeUtilities.full_day_ms);
  }

  on_play(player_view, fragment)
  {
    PhysicalCameraManager.selected_camera.fisheye_sphere.on_video_player_play(false);
    PhysicalCameraManager.selected_camera.map_marker.set_image_url(player_view.html_video.container);
  }
}
