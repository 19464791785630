import { Configuration } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';

const { pipelines } = window.mediaStreamLibrary;

/**
 * @class AxisCameraStream
 * @description Creates a stream that we are able to play a video, it acts like a media player.
 * @link https://github.com/AxisCommunications/media-stream-library-js
 */ 

// This handles the communication with a physical axis camera
export default class AxisCameraStream
{
  constructor(html_video)
  {
    this.html_video     = html_video; // HTMLVideo class

    this.user = undefined;
    this.password = undefined;
    this.host = undefined;
    this.recording_id = undefined;
    this.profile = undefined;
    this.play_offset = 0;

    this.is_streaming = false;
  }

  // Called when the camera settings are updated
  set_endpoint(camera)
  {
    // this.__stop_video_pipeline();
    this.user = camera.username;
    this.password = camera.password;
    this.host = camera.live_ip;
    this.resolution = camera.live_resolution;
  }

  // Called by MenuView and PlayerView.
  //
  // play_offset defines how many seconds of offset do you want when playing recording.
  // This is how we play a specific time of a specific recording.
  //
  // If play_offset is 0, the streaming of the recording starts
  // at the beginning of the recording
  set_recording_data(recording_id, play_offset)
  {
    this.recording_id = recording_id;
    this.play_offset  = play_offset;
  }

  set_profile(profile)
  {
    this.profile = profile;
  }

  set_resolution(resolution)
  {
    this.resolution = resolution;
  }

  // Called by MenuView and PlayerView
  start_streaming(on_video_ready)
  {
    // this.__stop_video_pipeline();
    this.__start_video_pipeline(this.__on_pipeline_ready.bind(this, on_video_ready));
  }

  play()
  {
    this.html_video.play();
  }

  pause()
  {
    this.html_video.pause();
  }

  stop()
  {
    this.html_video.stop();
    this.__stop_video_pipeline();
  }

  // This function performs the RTSP connection to a axis camera
  // through the websocket server.
  // This connection is managed by mediaStreamLibrary
  //
  // If this.recording_id is not present,
  // the Axis Camera will give us the live streaming
  //
  // If this.recording_id is present,
  // the Axis Camera will give us a specific recording
  __start_video_pipeline(on_ready_callback)
  {
    let user         = this.user;
    let password     = this.password;
    let host         = this.host;
    let mediaElement = this.html_video.container;
    let recording_id = this.recording_id;
    let encoding     = Configuration.is_ios ? 'jpeg' : 'h264';
    let profile      = this.profile;
    let resolution   = this.resolution;
    //let resolution   = Configuration.is_ios ? '1440x1440' : this.resolution;

    if (Configuration.is_ios)
    {
      resolution = '';
      profile = profile ? `${profile}_ios` : '';
    }

    let url = `rtsp://${host}/axis-media/media.amp?` +
              `videocodec=${encoding}` +
              '&audio=1' +
              `${resolution ? `&resolution=${resolution}` : ''}` +
              `&streamprofile=${profile}` +
              `${recording_id ? `&recordingid=${recording_id}` : ''}`;

   // console.log(`Requesting RTSP uri: ${url}`);
    //console.dir(this.html_video);

    let websocket_ip = this.__get_websocket_ip();
    let context = this;

    let data = {
      ws: { uri: `wss://${websocket_ip}/` },
      rtsp: {
        uri: url
      },
      mediaElement,
      auth: {
        username: user,
        password: password
      }
    };

    if (Configuration.is_ios)
    {
      this.pipeline = new pipelines.Html5CanvasPipeline(data);
    }
    else
    {
      this.pipeline = new pipelines.Html5VideoPipeline(data);
    }

    let pipeline        = this.pipeline;
    let play_offset     = this.play_offset;

    this.pipeline.ready.then(() =>
    {
      pipeline.rtsp.play(play_offset);
      // When the RTSP connection is ready to stream,
      // We call PlayerView.play()    
      pipeline.rtsp.onPlay = () =>
      {
        context.is_streaming = true;
        on_ready_callback();
      };
    });

    this.pipeline.ready.catch((e) =>
    {
      console.warn(`[AxisCameraStream:sdsds] Promise catch: pipeline.ready.catch`);
      console.dir(e);
    });
  }

  __on_pipeline_ready(callback)
  {
    if (callback)
    {
      callback();
    }
  }

  __stop_video_pipeline()
  {
    this.is_streaming = false;
    if (this.pipeline)
    {
      this.pipeline.rtsp.stop();
    }
  }

  __get_websocket_ip()
  {
    return ResourceContainer.get_resource('config').cameras_websocket_ip;
  }

  get_screenshot_url()
  {
    let scale = 1;
    const canvas = document.createElement('canvas');
    canvas.width = $(this.html_video.container).width() * scale;
    canvas.height = $(this.html_video.container).height() * scale;

    canvas.getContext('2d').drawImage(this.html_video.container, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL();
  }
}
