import RecordingsListRow from './html_components/menu_camera/RecordingsListRow';
import { ResourceContainer } from 'ohzi-core';
import PhysicalCameraManager from './PhysicalCameraManager';

// This class manages the rows of the RecordingsListView
class RecordingsListRowManager
{
  constructor()
  {
    this.recordings_list_rows = [];
    this.recordings_list_row = undefined;
    this.recordings_list_rows_container = undefined;
  }

  start()
  {
    this.recordings_list_rows_container = $('.recordings-list__table-body');
    this.recordings_list_row = $('.recordings-list__table-row--body');
  }

  add_row(fragment, start_time_ms, end_time_ms)
  {
    let app_config = ResourceContainer.get_resource('config');
    let camera_id = PhysicalCameraManager.selected_camera._id;

    let row = new RecordingsListRow(
      start_time_ms,
      end_time_ms,
      fragment,
      fragment.recording.downloadurl,
      this.recordings_list_row.clone()
    );

    this.recordings_list_rows.push(row);

    // Sort rows by start time
    this.recordings_list_rows = this.recordings_list_rows.sort(function(a, b)
    {
      return a.start_time_ms - b.start_time_ms;
    });

    this.recordings_list_rows_container.empty();
    for (let i = 0; i < this.recordings_list_rows.length; i++)
    {
      const list_row = this.recordings_list_rows[i];

      list_row.append_to(this.recordings_list_rows_container);
    }

    return row;
  }

  clear()
  {
    this.recordings_list_rows = [];
    this.recordings_list_rows_container.empty();
  }

  hide_download_buttons()
  {
    $('.recordings-list__button--download').addClass('hidden');
  }

  hide_delete_buttons()
  {
    $('.recordings-list__button--delete').addClass('hidden');
  }

  update()
  {
    for (let i = 0; i < this.recordings_list_rows.length; i++)
    {
      this.recordings_list_rows[i].update();
    }
  }
}

export default new RecordingsListRowManager();
