import ApplicationView from './common/ApplicationView';
import { Input } from 'ohzi-core';
import { Configuration } from 'ohzi-core';
import { Time } from 'ohzi-core';

import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import HTMLCanvas from './common/HTMLCanvas';
import HTMLVideo from './common/HTMLVideo';
import PlaybackBar from './PlaybackBar';
import PlayerViewMode from './player_view_mode/PlayerViewMode';
import LiveMode from './player_view_mode/LiveMode';
import RecordingMode from './player_view_mode/RecordingMode';
import ArchiveMode from './player_view_mode/ArchiveMode';
import PlayerSettings from '../PlayerSettings';
import PlayerExtractRecordingView from './PlayerExtractRecordingView';
import HTMLImage from './common/HTMLImage';
import AudioVolume from './AudioVolume';

/**
 * @class PlayerView 
 * @desription - is the class that handles all video playing requests.
 * @function play_recording() - Initalizes the video playback bar from the fragment that is passed. Called by RecodringsListView.
 * @function play() - Starts playing the selected video.Called by on_recording_played at RecordingsMode.
 */

export default class PlayerView extends ApplicationView
{
  constructor(app)
  {
    super('player', $('.player'));

    this.app = app;
    this.html_video = undefined;
    this.playback_bar = undefined;
    this.current_mode = new PlayerViewMode();

    this.initial_playback_time = 0;
    this.playback_time = 0;
    this.is_playing = false;
    this.should_set_video_image_to_marker = false;

    this.debug_mode = false;
  }

  start()
  {
    super.start();

    this.header                     = $('.player__header');
    this.play_button                = $('.player__play-button');
    this.pause_button               = $('.player__pause-button');
    this.body                       = $('.player__body');
    this.footer                     = $('.player__footer');
    this.live_label                 = $('.player__live-label');
    this.current_time               = $('.player__playback-time-label');

    this.params_label            = document.querySelector('.player__params-label');
    this.player_footer_body_elem = document.querySelector('.player__footer-body');

    this.controls_showing = false;

    this.__create_html_video();
    this.html_video.append_to(this.body[0]);
    this.html_video.container.addEventListener('canplaythrough', this.on_video_play.bind(this));

    this.playback_bar = new PlaybackBar();
    this.audio_volume = new AudioVolume();
    this.extract_recording_view = new PlayerExtractRecordingView(this, this.app.calendar_view);

    this.playback_bar.start();
    this.audio_volume.start(this.html_video);
    this.extract_recording_view.start();
  }

  update()
  {
    // Show controls if the mouse is inside the player
    if (Input.mouse_is_within_bounds(this.containerEl.getBoundingClientRect()))
    {
      this.__show_controls();
    }
    else
    {
      this.__hide_controls();
    }

    this.playback_bar.update();
    this.audio_volume.update();
    this.extract_recording_view.update();
    this.current_mode.update(this);

    this.playback_time += Time.delta_time * 1000 * this.time_scale;

    // console.log(`playback_time: ${this.playback_time} | ${this.time_scale}`);
    // Show info of the selected camera
    if (PlayerSettings.camera_info_enabled && PhysicalCameraManager.selected_camera)
    {
      this.__show_camera_info();
    }
    else
    {
      this.__hide_camera_info();
    }

    if (this.should_set_video_image_to_marker)
    {
      if (this.html_video.is_image_showing())
      {
        let selected_camera = PhysicalCameraManager.selected_camera;
        if (selected_camera)
        {
          selected_camera.map_marker.set_image_url(selected_camera.stream.get_screenshot_url());
          this.should_set_video_image_to_marker = false;
        }
      }
    }
  }

  // This function is called by MenuView
  set_live_mode()
  {
    this.set_mode(new LiveMode());
  }

  // This function is called by MenuView
  set_recordings_mode()
  {
    this.set_mode(new RecordingMode());
  }

  set_archive_mode()
  {
    this.set_mode(new ArchiveMode());
  }

  set_mode(new_mode)
  {
    this.current_mode.on_exit(this);
    this.current_mode = new_mode;
    this.current_mode.on_enter(this);
  }

  // Play the current recording
  play()
  {
    this.time_scale = 1;

    this.play_button.addClass('hidden');
    this.pause_button.removeClass('hidden');

    let selected_camera = PhysicalCameraManager.selected_camera;

    if (selected_camera)
    {
      selected_camera.fisheye_sphere.visible = true;
      this.app.player_container.hide_loading();

      // console.dir(this.html_video);
      this.html_video.play();
      selected_camera.play();

      // Only call telemetry start if video has already been loaded to prevent calling twice
      // if (this.html_video.container.readyState === 4)
      if (!selected_camera.is_telemetry_streaming)
      {
        let ms = this.playback_time;
        let fragment = this.playback_bar.get_fragment_contained_at_ms(ms);

        // If the user clicked inside the recording fragment
        if (fragment)
        {
          this.current_mode.on_play(this, fragment);
        }
      }

      if (selected_camera.lens_type === 'fisheye')
      {
        this.should_set_video_image_to_marker = true;
      }
    }

    let selected_camera_tandem = PhysicalCameraManager.selected_camera_tandem;

    if (selected_camera_tandem)
    {
      selected_camera_tandem.fisheye_sphere.visible = true;
      console.dir(selected_camera_tandem.plane_stream.html_video);
      selected_camera_tandem.plane_stream.html_video.play();

      // this.html_video.play();

      if (selected_camera_tandem.lens_type === 'fisheye')
      {
        this.should_set_video_image_to_marker = true;
      }
    }
  }

  // Tandem view video plane
  tandem_plane_view_play(camera)
  {
    camera.plane_stream.html_video.container.play();
    camera.plane_stream.html_video.play();

    let videoElement = camera.plane_stream.html_video.container;
    camera.map_marker.set_image_url(videoElement);
    camera.map_marker.set_video_resolution(videoElement.videoHeight, videoElement.videoWidth);
    camera.map_marker.show();
  }

  // Playing webrtc video plane without main plaer
  webrtc_plane_view_play(camera)
  {
    if (!camera)
    {
      return;
    }
    console.log('[webrtc_plane_view_play]');
    camera.stream.html_video.play();
    let videoElement = camera.stream.html_video.container;
    camera.map_marker.set_image_url(videoElement);
    camera.map_marker.set_video_resolution(videoElement.videoHeight, videoElement.videoWidth);
    camera.map_marker.show();
  }

  // Called from 'canplaythrough' event
  on_video_play()
  {
    let ms = this.playback_time;
    let fragment = this.playback_bar.get_fragment_contained_at_ms(ms);

    if (PhysicalCameraManager.selected_camera)
    {
      // If the user clicked inside the recording fragment
      this.current_mode.on_play(this, fragment);
    }
  }

  // Pause the current recording - Called from pause button on GUI
  pause()
  {
    this.time_scale = 0;

    this.play_button.removeClass('hidden');
    this.pause_button.addClass('hidden');

    if (PhysicalCameraManager.selected_camera)
    {
      PhysicalCameraManager.selected_camera.pause();
      this.current_mode.on_pause(this);
    }

    this.html_video.pause();

    let selected_camera_tandem = PhysicalCameraManager.selected_camera_tandem;

    if (selected_camera_tandem)
    {
      // console.dir(selected_camera_tandem.plane_stream.html_video);
      selected_camera_tandem.pause();
      selected_camera_tandem.plane_stream.html_video.pause();
    }
  }

  // This function is called by DetailedRecordingMode when the user clicks on the playback bar.
  play_recording_at_ms(ms)
  {
    this.playback_bar.recalculate_bounding_client();

    let fragment = this.playback_bar.get_fragment_contained_at_ms(ms);

    // If the user clicked inside the recording fragment
    if (fragment)
    {
      this.play_recording(fragment, ms);
    }
  }

  // Play a recording at a specific time
  // or from the beginning if playback_time is not present
  play_recording(fragment, playback_time)
  {
    this.pause();

    this.initial_playback_time = playback_time || fragment.start_ms;
    this.playback_time = playback_time || fragment.start_ms;

    this.playback_bar.set_current_fragment_playing(fragment);

    this.app.menu_view.current_tab.on_recording_played(this, fragment);
  }

  // Called by MainApplication when the browser window is resized
  on_resize()
  {
    if (this.fragment_manager)
    {
      this.fragment_manager.on_resize();
    }
  }

  __create_html_video()
  {
    if (Configuration.is_ios)
    {
      // this.html_video = new HTMLImage('player__video');
      this.html_video = new HTMLCanvas('player__video');
    }
    else
    {
      this.html_video = new HTMLVideo('player__video', false);
    }
  }

  __show_camera_info()
  {
    // console.log(`[PlayerView:__show_camera_info]`);
    // console.dir(this.html_video.container);

    let currentTime = 0;
    let bufferedTime = 0;
    let lagTime = 0;

    if (!Configuration.is_ios)
    {
      currentTime = this.html_video.container.currentTime.toFixed(1);  // vid.currentTime ;
      if (this.html_video.container.readyState === 4 && this.html_video.container.buffered.length > 0)
      {
        bufferedTime = this.html_video.container.buffered.end(0).toFixed(1);
      } // vid.buffered.end(0);
      lagTime = bufferedTime - currentTime;
    }

    let label_image_pan_tilt = '';
    let label_icon_pan_tilt = '';
    let label_vid_time = '';
    let label_ptz = '';

    if (this.debug_mode)
    {
      label_image_pan_tilt = `Image Pan: ${PhysicalCameraManager.selected_camera.image_pan.toFixed(2)} Image Tilt: ${PhysicalCameraManager.selected_camera.image_tilt.toFixed(2)} <br>`;
      label_image_pan_tilt = `Icon Pan: ${PhysicalCameraManager.selected_camera.icon_pan.toFixed(2)} Icon Tilt: ${PhysicalCameraManager.selected_camera.icon_tilt.toFixed(2)} <br>`;
      if (!Configuration.is_ios)
      {
        label_vid_time = `Video time: ${currentTime} / ${bufferedTime} => lag: ${lagTime.toFixed(1)} <br>`;
      }
      if (PhysicalCameraManager.selected_camera.ptz_pan)
      {
        label_ptz = `${PhysicalCameraManager.selected_camera.ptz_pan ? `PTZ - pan: ${PhysicalCameraManager.selected_camera.ptz_pan}` : ''}` +
          `${PhysicalCameraManager.selected_camera.ptz_tilt ? ` tilt: ${PhysicalCameraManager.selected_camera.ptz_tilt}` : ''}` +
          `${PhysicalCameraManager.selected_camera.ptz_zoom ? ` zoom: ${PhysicalCameraManager.selected_camera.ptz_zoom} <br>` : ''}`;
      }
    }

    let label_time = moment.parseZone(PhysicalCameraManager.selected_camera.time, ['YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DDTHH:mm:sZ']).format('YYYY-MM-DD HH:mm:ssZ');

    let html =
      `${PhysicalCameraManager.selected_camera.time ? `Time: ${label_time} <br>` : ''}` +
      `${PhysicalCameraManager.selected_camera.cam_lat ? `Loc: ${parseFloat(PhysicalCameraManager.selected_camera.cam_lat).toFixed(6)}, ${parseFloat(PhysicalCameraManager.selected_camera.cam_lon).toFixed(6)}, ${parseFloat(PhysicalCameraManager.selected_camera.cam_alt).toFixed(0)} <br>` : ''}` +
      `Y/P/R: ${PhysicalCameraManager.selected_camera.yaw}/${PhysicalCameraManager.selected_camera.pitch}/${PhysicalCameraManager.selected_camera.roll} <br>` +
      label_image_pan_tilt +
      label_image_pan_tilt +
      label_ptz +
      label_vid_time;

    if (html !== this.params_label.innerHTML)
    {
      this.params_label.innerHTML = html;
    }
  }

  __hide_camera_info()
  {
    let html = '';

    if (html !== this.params_label.innerHTML)
    {
      this.params_label.innerHTML = html;
    }
  }

  __show_controls()
  {
    if (!this.controls_showing)
    {
      this.__show_element(this.header);
      this.__show_element(this.footer);
      this.__show_element(this.extract_recording_view.container);

      // Move live label
      this.live_label[0].style['transition-delay'] = '0s';
      this.live_label[0].style.transform = 'translate3d(0, 0, 0)';

      this.controls_showing = true;
    }
  }

  __hide_controls()
  {
    if (this.controls_showing)
    {
      this.__hide_element(this.header);
      this.__hide_element(this.footer);
      this.__hide_element(this.extract_recording_view.container);

      // Move live label
      this.live_label[0].style['transition-delay'] = '0.5s';
      this.live_label[0].style.transform = `translate3d(0, -${this.header.height()}px, 0)`;

      this.controls_showing = false;
    }
  }

  __show_element(element)
  {
    element[0].style['transition-delay'] = '0s';
    element[0].style.opacity = 1;
  }

  __hide_element(element)
  {
    element[0].style['transition-delay'] = '0.5s';
    element[0].style.opacity = 0;
  }
}
